<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-select
          :label="item.label || 'Country'"
          item-text="name"
          persistent-hint
          item-value="id"
          :items="countries"
          v-model="country_id"
          v-on:change="setValue(true)"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          label="City"
          item-text="name"
          item-value="id"
          focusable
          :items="citiesArray"
          v-model="city_id"
          v-on:change="setValue(false)"
        ></v-select>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog.statu" persistent hide-overlay width="300">
      <v-card :color="dialog.color" dark>
        <v-card-text class="p-3 white--text text-center">
          <v-icon>
            mdi-alert-octagon
          </v-icon>
          {{ dialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="dialog.statu = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CountryandCity",
  props: ["item", "countries", "islookup"],
  created() {
    let myData =
      this.item && this.item.value && this.item.value != ""
        ? JSON.parse(this.item.value)
        : { country: "", city: "" };

    this.country_id = Number(myData.country);
    this.city_id = Number(myData.city);
    this.getCities();
  },
  data() {
    return {
      data: [],
      dialog: {
        statu: false,
        color: "red",
        text: "Loading, Please wait",
      },
      citiesArray: [],
      token: localStorage.getItem("authToken"),
      loading: true,
      country_id: null,
      city_id: null,
    };
  },
  methods: {
    setValue: function(type = false) {
      const obj = {
        country: this.country_id,
        city: this.city_id,
      };

      this.item.value = JSON.stringify(obj);

      if (type == true) {
        this.getCities();
        this.dialog = {
          statu: true,
          text: "Cities list is refresh, Please choose again.",
          color: "primary",
        };
      }
    },
    getCities: function() {
      this.citiesArray = [];
      let url =
        "https://gowawe.com/api/admin/country/" +
        this.country_id +
        "/cities?api_token=" +
        this.token;
      axios.get(url).then((res) => {
        res.data.data.forEach((item) => {
          this.citiesArray.push({
            id: item.id,
            name: item.name,
          });
        });
      });
    },
  },
};
</script>

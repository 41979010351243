<template>
  <div class="p-3 mb-5">
    <v-card>
      <v-tabs background-color="primary" dark grow v-model="model">
        <v-tab v-for="(x, i) in tabs" :key="i" :href="`#tab-${i}`">{{
          x.name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="model">
        <v-tab-item v-for="(x, i) in tabs" :key="i" :value="`tab-${i}`">
          <v-card flat class="p-2">
            <v-card-title>{{ x.name }}</v-card-title>
            <v-card-subtitle>{{ x.subname }}</v-card-subtitle>
            <keep-alive>
              <wizardtabs
                :name="x.name"
                v-bind:is="'tabcontent' + i"
                :countries="countries"
                :memberdomain="memberdomain"
                :onlinemembersset="onlinemembersset"
              ></wizardtabs>
            </keep-alive>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Tabcontent1 from "./Reports/Tabcontent1.vue";
import Tabcontent2 from "./Reports/Tabcontent2.vue";
import Tabcontent3 from "./Reports/Tabcontent3.vue";

export default {
  name: "Reports",
  props: ["member_id", "countries", "memberdomain", "onlinemembers"],
  watch: {
    onlinemembers: function(newVal, oldVal) {
      this.onlinemembersset = newVal;
    },
  },
  data: function() {
    return {
      onlinemembersset: [],
      data: null,
      model: "tab-0",
      tabs: [
        {
          name: "Visits",
          subname: "Shows the reporting of members visiting the store.",
        },
        {
          name: "Online",
          subname: "Shows online users visiting the store.",
        },
        {
          name: "Contacts",
          subname: "Shows users who want to see their contact information.",
        },
      ],
    };
  },
  components: {
    tabcontent0: Tabcontent1, // Visits
    tabcontent1: Tabcontent2, // Visits
    tabcontent2: Tabcontent3, // Visits
  },
};
</script>

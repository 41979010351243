<template>
  <v-card class="p-3">
    <v-card-title>
      Member Logs
      <v-btn
        class="ml-3"
        color="primary"
        small
        :loading="loading"
        v-on:click="getList"
        >Refresh List</v-btn
      >
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Date & Time
            </th>
            <th class="text-left">
              User ID
            </th>
            <th class="text-left">
              Visited URL
            </th>
            <th class="text-left">
              Search Key
            </th>
            <th class="text-left">
              Visiter Site Lang
            </th>
            <th class="text-left">
              IP ID
            </th>
            <th class="text-left">
              Refferer Site Name
            </th>
            <th class="text-left">
              Refferer Site
            </th>
            <th class="text-left">
              Timezone
            </th>
            <th class="text-left">
              Country
            </th>
            <th class="text-left">
              Region
            </th>
            <th class="text-left">
              Visiter Device
            </th>
            <th class="text-left">
              Visiter Device Lang
            </th>
            <th class="text-left">
              Device OS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="'hs-' + index">
            <td>{{ item.visit.created_at }}</td>
            <td>{{ item.member.user_id }}</td>
            <td>{{ item.redirected_url }}</td>
            <td>...</td>
            <td>{{ item.site_lang }}</td>
            <td>{{ item.visit_ip.id }}</td>
            <td>{{ item.referrer_site_name }}</td>
            <td>{{ item.referrer_site_url }}</td>
            <td>{{ item.visit_timezone }}</td>
            <td>{{ item.country }}</td>
            <td>{{ item.region }}</td>
            <td>{{ item.is_mobile ? "Mobile" : "Desktop" }}</td>
            <td>{{ item.device_lang }}</td>
            <td>{{ item.os }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "MemberLogs",
  data: function() {
    return {
      data: [],
      loading: true,
    };
  },
  methods: {
    getList() {
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "member-general-visits/" +
            this.$route.params.id
        )
        .then((res) => {
          this.data = res.data.visits.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    matchCountry(id) {
      if (this.countries.find((a) => a.id == id)) {
        let cu = this.countries.find((a) => a.id == id);
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
  created() {
    this.getList();
  },
  //   watch: {
  //     countries(newVal, oldVal) {
  //       let x = {};
  //       newVal.forEach((item) => {
  //         x[item.country_code] = Math.floor(Math.random() * 100) + 1;
  //       });
  //       this.data = x;
  //     },
  //   },
};
</script>

<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ID
            </th>
            <th class="text-left">
              Name Surname
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Phone
            </th>
            <th class="text-left">
              Company Name
            </th>
            <th class="text-left">
              Store URL
            </th>
            <th class="text-left">
              Country
            </th>
            <th class="text-left">
              Created at
            </th>
            <th class="text-left">
              Updated at
            </th>
            <th class="text-left">
              How many times viewed?
            </th>
            <th class="text-left">
              Detail
            </th>
          </tr>
        </thead>
        <tbody v-if="data">
          <tr v-for="(item, index) in data" :key="'hs-' + index">
            <td>{{ item.interaction_member.id }}</td>
            <td>
              {{
                item.interaction_member.first_name +
                  " " +
                  item.interaction_member.last_name
              }}
            </td>
            <td>
              {{
                item.interaction_member.store
                  ? item.interaction_member.store.email
                  : ""
              }}
            </td>
            <td>
              +{{ item.interaction_member.country_code }}
              {{ item.interaction_member.phone }}
            </td>
            <td>
              {{
                item.interaction_member.store
                  ? item.interaction_member.store.company_name
                  : ""
              }}
            </td>
            <td>
              <div
                v-if="
                  item.interaction_member.store &&
                    item.interaction_member.store.domain
                "
              >
                {{
                  "https://" +
                    item.interaction_member.store.domain +
                    "gowawe.com"
                }}
              </div>
            </td>
            <td>
              <div
                v-html="matchCountry(item.interaction_member.country_id)"
              ></div>
            </td>
            <td>
              {{ item.created_at }}
            </td>
            <td>{{ item.updated_at }}</td>
            <td>{{ item.interaction_count }}</td>
            <td>
              <v-btn
                elevation="0"
                link
                color="primary"
                v-on:click="
                  (id = item.interaction_member.id),
                    getDetail(),
                    (dialog = true)
                "
                ><v-icon>
                  mdi-crosshairs-question
                </v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title>
          Viewed Details <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="getDetail()" icon>
            <v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Name Surname
                  </th>
                  <th class="text-left">
                    URL
                  </th>
                  <th class="text-left">
                    Moment
                  </th>
                  <th class="text-left">
                    Created at
                  </th>
                </tr>
              </thead>
              <tbody v-if="detail">
                <tr v-for="(item, index) in detail" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.interaction_user.name }}</td>
                  <td>{{ item.referrer_site_url }}</td>
                  <td>{{ moment(item.created_at).fromNow() }}</td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader type="list-item"></v-skeleton-loader>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            class="text-center py-3 text-h6 w-100"
            v-if="detail && detail.length == 0"
          >
            Sorry, No Any Data
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(dialog = false), (detail = null), (id = null)"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MapChart from "vue-chart-map";
import axios from "axios";
import moment from "moment";

export default {
  name: "ReportsTabcontent3",
  props: ["countries"],
  components: {
    MapChart: MapChart,
  },
  data: function() {
    return {
      moment: moment,
      dialog: false,
      data: [],
      detail: null,
      id: null,
    };
  },
  methods: {
    getDetail() {
      this.detail = null;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "contact-view-detail?store_member=" +
            this.$route.params.id +
            "&interaction_member=" +
            this.id
        )
        .then((res) => {
          this.detail = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getList() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "contact-views/" + this.$route.params.id
        )
        .then((res) => {
          this.data = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    matchCountry(id) {
      if (this.countries.find((a) => a.id == id)) {
        let cu = this.countries.find((a) => a.id == id);
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
  created() {
    this.getList();
  },
  watch: {
    countries(newVal, oldVal) {
      let x = {};
      newVal.forEach((item) => {
        x[item.country_code] = Math.floor(Math.random() * 100) + 1;
      });
      this.data = x;
    },
  },
};
</script>

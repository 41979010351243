<template>
  <div>
    <p>You can edit: Photos</p>
    <v-card class="p-3 flex-wrap d-flex">
      <v-col cols="12">
        <v-img
          :src="
            formdata.wizarddata.model.cover_photo ||
              'https://via.placeholder.com/940x360'
          "
          max-width="940"
          max-height="360"
          class="mx-auto"
        ></v-img>
        <v-card flat max-width="500" class="mx-auto">
          <v-file-input
            v-on:change="uploadPhoto('cover')"
            :loading="loading_cover"
            v-model="cover_photo"
            ref="cover"
            label="Cover Photo"
          ></v-file-input>
        </v-card>
      </v-col>
      <v-col cols="12 text-center">
        <v-avatar size="200">
          <v-img
            :src="
              formdata.wizarddata.model.logo ||
                'https://via.placeholder.com/200'
            "
            max-width="200"
            max-height="200"
          ></v-img>
        </v-avatar>
        <v-card
          flat
          max-width="500"
          class="mx-auto"
          :loading="loading_logo"
          ref="logo"
          v-model="logo_photo"
          v-on:change="uploadPhoto('logo')"
        >
          <v-file-input label="Logo"></v-file-input>
        </v-card>
      </v-col>
      <v-col cols="12 text-center">
        <v-layout align-center justify-center>
          <v-img
            src="https://via.placeholder.com/200x200"
            max-width="150"
            max-height="150"
            class="m-1"
          ></v-img>
        </v-layout>
        <v-card flat max-width="500" class="mx-auto">
          <v-file-input label="Home Slider Photo"></v-file-input>
        </v-card>
      </v-col>
      <v-col cols="12 text-center">
        <v-layout align-center justify-center>
          <v-img
            src="https://via.placeholder.com/200x200"
            max-width="150"
            max-height="150"
            class="m-1"
          ></v-img>
        </v-layout>
        <v-card flat max-width="500" class="mx-auto">
          <v-file-input label="Profile Slider Photo"></v-file-input>
        </v-card>
      </v-col>
      <v-col cols="12 text-center">
        <v-img
          src="https://via.placeholder.com/700x200"
          max-width="700"
          max-height="200"
          class="mx-auto"
        ></v-img>
        <v-card flat max-width="500" class="mx-auto">
          <v-file-input label="360 Slider Photo"></v-file-input>
        </v-card>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Tabcontent3",
  props: ["formdata"],
  data() {
    return {
      loading_cover: false,
      loading_logo: false,
      cover_photo: null,
      logo_photo: null,
      norules: [],
      formfields: [],
      rules: [(v) => !!v || "Field is required"],
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    uploadPhoto(type) {
      let dataF = new FormData();
      let filex;

      if (type == "cover") {
        filex = this.cover_photo;
        this.loading_cover = true;
        dataF.append("type", "cover_photo");
      } else if (type == "logo") {
        filex = this.logo_photo;
        this.loading_logo = true;
        dataF.append("type", "logo");
      }

      dataF.append("file", filex);
      dataF.append("api_token", this.token);

      axios
        .get("https://dashboard.gowawe.com/tr/upload-base", dataF, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (type == "cover") {
            this.formdata.wizarddata.model.cover_photo = res.data.file_paths[0];
          } else if (type == "logo") {
            this.formdata.wizarddata.model.logo = res.data.file_paths[0];
          }
          this.loading_cover = false;
          this.loading_logo = true;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    //console.log(this.formdata);
  },
};
</script>

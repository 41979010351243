<template>
  <div class="data-wrapper">
    <v-card tile>
      <v-card-title>Uploaded Files</v-card-title>
      <v-list-item v-for="(item, index) in data" :key="index">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium mb-3">{{
            itemTitle[index]
          }}</v-list-item-title>

          <div class="wrapper_row_item w-100">
            <div
              v-if="item.files && item.files.length > 0"
              class="wrapper_row_content"
            >
              <div
                class="d-flex flex-wrap w-100 wrapper_line_item"
                v-for="(file, index) in item.files"
                :key="index"
              >
                <div class="item_wrapper">
                  <label>File Name</label>
                  <p>{{ file.name }}</p>
                </div>
                <div class="item_wrapper">
                  <label>File Size</label>
                  <p>{{ file.size }}</p>
                </div>
                <div class="item_wrapper">
                  <label>File Type</label>
                  <p>{{ file.type }}</p>
                </div>
                <div class="item_wrapper">
                  <label>File Date</label>
                  <p>{{ file.time }}</p>
                </div>
                <div class="item_wrapper">
                  <label>File Path</label>
                  <p>{{ file.path }}</p>
                  <a :href="file.path" target="_blank">
                    <v-icon small>mdi-share</v-icon> Open Path</a
                  >
                  <br />
                  <a :href="file.path" :download="file.name"
                    ><v-icon small>mdi-download</v-icon> Download File</a
                  >
                  <div>
                    <img :src="file.path" width="100" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>No uploaded data yet</div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-snackbar v-model="snackbar">
      This user has not uploaded any official files.
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "StoreVerifyShow",
  data() {
    return {
      e1: 1,
      snackbar: false,
      loading: true,
      data: [],
      itemTitle: [
        "Contract",
        "Tax Plate",
        "Tax Plate",
        "Industry Registration Certificate",
        "Signature Circular",
        "Trade Registry Gazette",
        "Trade Certificate of Registry",
        "Owner ID",
      ],
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    getData: function(id) {
      let url =
        "https://gowawe.com/api/admin/store-files/" +
        id +
        "?api_token=" +
        this.token;
      axios.get(url).then((res) => {
        const response = res.data;
        if (response && response.status) {
          if (!response.data) {
            this.snackbar = true;
          }
          this.data = response.data;
        } else {
          this.snackbar = true;
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style scoped>
.wrapper_row_item {
  background: #f3f3f3;
  padding: 1em;
  border-radius: 4px;
}
.wrapper_row_content {
  padding-left: 3em;
}
.wrapper_line_item {
  margin: 2em 0;
  padding: 1em;
  background: #fff;
}
.item_wrapper {
  padding: 0.5em;
  border: 1px solid #eee;
  min-width: 25%;
  margin: 5px 0;
  font-size: 13px;
}
label {
  font-weight: 600;
}
</style>

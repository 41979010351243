<template>
  <div class="table_wrapper">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          editable
          v-on:input="seturl"
        >
          User Information
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" editable v-on:input="seturl">
          Password
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" editable>
          RFQ List
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" editable>
          RFQ Offer List
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="5" :editable="iswizardActive">
          Store Wizard
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="6" editable>
          Subscriptions
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="7" editable>
          Products
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="8" editable>
          Gowawe Posts
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="9" editable>
          Facebook Posts
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="10" editable>
          Reports
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="11" editable>
          Visited Store
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="12" editable>
          Online
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="13" editable>
          Logs
        </v-stepper-step>
        <v-stepper-step step="14" editable>
          Search
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="3">
                <v-select
                  label="Account Type"
                  item-text="name"
                  item-value="id"
                  :loading="loading"
                  v-model="formdata.account_type"
                  :items="accounttypes"
                  v-on:change="accounttypechange"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :loading="loading"
                  v-model="formdata.email"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :loading="loading"
                  v-model="formdata.first_name"
                  label="First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :loading="loading"
                  v-model="formdata.last_name"
                  label="Last Name"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  :loading="loading"
                  v-model="formdata.how_find_gowawe"
                  label="How to Find Gowawe?"
                  item-text="name"
                  item-value="id"
                  :items="howfindgowawe"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :loading="loading"
                  v-model="formdata.selected_lang"
                  label="Notifications main language"
                  item-text="name"
                  item-value="id"
                  :items="selectlocalies"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :loading="loading"
                  label="Phone Number Code"
                  v-model="formdata.country_code"
                  :items="phonecodes"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :loading="loading"
                  v-model="formdata.phone"
                  label="Phone Number"
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="isStore == true">
                <v-text-field
                  :loading="loading"
                  v-model="formdata.company_name"
                  label="Company Name"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  :loading="loading"
                  label="Please Select Country"
                  item-text="name"
                  item-value="id"
                  v-on:change="getCities"
                  v-model="formdata.country_id"
                  :items="countries"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :loading="loading"
                  label="Please Select City"
                  item-text="name"
                  item-value="id"
                  v-model="formdata.city_id"
                  :items="cities"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <p class="subtitle-1 mb-0">Created At</p>
                <p class="subtitle-2">{{ created_at }}</p>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="formdata.address"
                  :value="formdata.address"
                  :loading="loading"
                  label="Address"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn
                class="ml-3"
                color="green"
                dark
                :disabled="dialog"
                :loading="dialog"
                v-on:click="saveAllData"
              >
                Save
              </v-btn>
              <v-btn color="primary" @click="e1 = 2">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="p-3">
            <v-btn color="primary" v-on:click="passLock = !passLock">
              <v-icon>mdi-lock-reset</v-icon>
              Reset Password
            </v-btn>

            <div class="mt-4">
              <v-alert
                color="success darken-1"
                max-width="500"
                dark
                class="text-center"
                transition="scale-transition"
                prominent
                text
                type="info"
                :value="isPassUpdate"
              >
                {{ pass.password }}
              </v-alert>
            </div>

            <v-card class="my-5" v-if="passLock">
              <v-card-title>
                <v-icon class="mr-3">mdi-formdata-textbox-password</v-icon>
                Reset Password</v-card-title
              >
              <v-row align="center" class="p-3">
                <v-col cols="3">
                  <v-text-field
                    :type="password_type ? 'text' : 'password'"
                    v-model="pass.password"
                    label="Password"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :type="password_type ? 'text' : 'password'"
                    v-model="pass.passwordConfirm"
                    label="RePassword"
                    :append-icon="password_type ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="password_type = !password_type"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    class="mr-3"
                    v-on:click="makeStrongPass"
                  >
                    Make a Strong Password
                  </v-btn>

                  <v-btn
                    v-if="pass.password.trim() != ''"
                    color="success"
                    :disabled="pass.password != pass.passwordConfirm"
                    v-on:click="savePass"
                  >
                    <v-icon>mdi-chec</v-icon>
                    Save New Password
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn @click="e1 = 1" class="ml-3">
                Prev
              </v-btn>
              <v-btn @click="e1 = 3" class="ml-3" color="primary">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="p-3">
            <rfq :member_id="$route.params.id"></rfq>
            <v-card-actions>
              <v-btn @click="e1 = 2" class="ml-3">
                Prev
              </v-btn>
              <v-btn @click="e1 = 4" class="ml-3" color="primary">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="p-3">
            <rfqoffers :member_id="$route.params.id"></rfqoffers>

            <v-card-actions>
              <v-btn @click="e1 = 3" class="ml-3">
                Prev
              </v-btn>
              <v-btn
                @click="iswizardActive == false ? (e1 = 6) : (e1 = 5)"
                class="ml-3"
                color="primary"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card class="p-3">
            <v-card-title>Store Wizard</v-card-title>

            <storewizard
              v-if="pageisready"
              :businesstypes="businessTypes"
              :formdata="formdata"
              :formfields="formFields"
              :fielddata="fielddata"
              :countries="countries"
              :cities="cities"
            ></storewizard>

            <v-card-actions>
              <v-btn @click="e1 = 4" class="ml-3">
                Prev
              </v-btn>
              <v-btn
                class="ml-3"
                color="success"
                :disabled="dialogPass"
                :loading="dialogPass"
                v-on:click="saveAllData2"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="6">
          <v-card class="p-3">
            <v-card-title>
              Subscriptions
              <v-btn
                color="primary"
                class="ml-3"
                small
                v-on:click="changePackage = true"
                >Change Package</v-btn
              >
            </v-card-title>
            <v-card max-width="400" flat class="p-2" v-if="changePackage">
              <v-layout wrap align-center>
                <v-select
                  label="Package List"
                  :items="packagesAll"
                  item-value="id"
                  item-text="title"
                  v-model="selectedPackage"
                  return-object
                ></v-select>
                <v-btn
                  color="primary"
                  class="ml-3"
                  small
                  v-on:click="updatePackage"
                  >Update</v-btn
                >
              </v-layout>
            </v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Started at
                    </th>
                    <th class="text-left">
                      Start Moment
                    </th>
                    <th class="text-left">
                      End Date
                    </th>
                    <th class="text-left">
                      End Moment
                    </th>
                    <th class="text-left">
                      Payment Type
                    </th>
                    <th class="text-left">
                      Price
                    </th>
                    <th class="text-left">
                      Is Active
                    </th>
                    <th class="text-left">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in packages" :key="index">
                    <td>
                      <v-icon color="green">mdi-star-circle</v-icon>
                      {{ item.package.title }}
                    </td>
                    <td>{{ item.start_date }}</td>
                    <td>{{ moment(item.start_date).fromNow() }}</td>
                    <td>{{ item.end_date }}</td>
                    <td>
                      {{ item.end_date ? moment(item.end_date).fromNow() : "" }}
                    </td>
                    <td>{{ item.payment_type }}</td>
                    <td>{{ item.price }}</td>
                    <td>
                      <v-chip
                        dark
                        :color="item.status == 'active' ? 'green' : 'red'"
                      >
                        {{ item.status }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="warning"
                        :to="
                          '/subscriptions/edit/' + item.subscription_package_id
                        "
                        class="mx-1"
                        fab
                        small
                        elevation="2"
                      >
                        <v-icon small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-btn
                @click="iswizardActive == false ? (e1 = 4) : (e1 = 5)"
                class="ml-3"
              >
                Prev
              </v-btn>
              <v-btn @click="e1 = 7" disabled class="ml-3">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="7">
          <v-card class="p-3">
            <products></products>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="8">
          <v-card class="p-3">
            <posts :type="'gowawe'"></posts>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="9">
          <v-card class="p-3">
            <v-layout class="mb-3">
              <v-btn
                :color="tabpost == 1 ? 'primary' : 'accent'"
                class="mx-2"
                v-on:click="tabpost = 1"
                key="1"
              >
                All Posts
              </v-btn>
              <v-btn
                :color="tabpost == 2 ? 'primary' : 'accent'"
                class="mx-2"
                v-on:click="tabpost = 2"
                key="2"
              >
                Added By Me
              </v-btn>
              <v-btn
                :color="tabpost == 3 ? 'primary' : 'accent'"
                class="mx-2"
                v-on:click="tabpost = 3"
                key="3"
              >
                Schedule
              </v-btn>
              <v-btn
                :color="tabpost == 4 ? 'primary' : 'accent'"
                class="mx-2"
                v-on:click="tabpost = 4"
                key="4"
              >
                Published Posts
              </v-btn>
            </v-layout>

            <facebook-posts
              :isx="'all'"
              v-if="tabpost == 1"
              key="1"
            ></facebook-posts>
            <facebook-posts
              :isx="'me'"
              v-if="tabpost == 2"
              key="2"
            ></facebook-posts>
            <schedules v-if="tabpost == 3"></schedules>
            <facebook-posts
              :isx="'published'"
              v-if="tabpost == 4"
              key="4"
            ></facebook-posts>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="10">
          <v-card class="p-3">
            <v-card-title>
              Reports
            </v-card-title>
            <reports
              :memberdomain="formdata.domain"
              :onlinemembers="onlineAllUsers"
              :countries="countries"
              :member_id="$route.params.id"
            ></reports>

            <v-card-actions>
              <v-btn @click="e1 = 6" class="ml-3">
                Prev
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="11">
          <v-card class="p-3">
            <v-card-title>
              Visited by Store
            </v-card-title>
            <v-card-subtitle>
              Pages previously visited by
              <b>{{ formdata.first_name }} {{ formdata.last_name }}</b>
            </v-card-subtitle>

            <v-card-text>
              <userhistorystatu
                ref="userhistorystatu"
                :countries="countries"
              ></userhistorystatu>
            </v-card-text>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="12">
          <v-card class="p-3">
            <v-card-title>
              User Online
            </v-card-title>
            <useronlinestatu
              :countries="countries"
              :onlinemembers="onlineMembers"
            ></useronlinestatu>

            <v-card-actions>
              <v-btn @click="e1 = 10" class="ml-3">
                Prev
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="13">
          <memberlogs :countries="countries"></memberlogs>
        </v-stepper-content>

        <v-stepper-content step="14">
          <SearchHistory :member_id="this.$route.params.id"></SearchHistory>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialogPass" persistent hide-overlay width="300">
      <v-card :color="dialogPassColor" dark>
        <v-card-text class="p-3 white--text text-center">
          <v-icon>
            mdi-alert-octagon
          </v-icon>
          {{ passLoadingText }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import StoreWizardVue from "../Components/StoreWizard.vue";
import Rfq from "./Rfq.vue";
import RfqOffers from "./RfqOffers.vue";

import reports from "../Components/Reports.vue";
import useronlinestatu from "../Components/UserOnlineStatu.vue";
import userhistorystatu from "../Components/UserHistoryStatu.vue";
import memberlogs from "../Components/MemberLogs.vue";
import moment from "moment";
import Products from "./Products.vue";
import Posts from "./Posts.vue";
import FacebookPosts from "./Facebook Posts.vue";
import Schedules from "./Schedules.vue";
import SearchHistory from "../Components/MembersEdit/SearchHistory.vue";

export default {
  name: "MembersEdit",
  components: {
    storewizard: StoreWizardVue,
    rfq: Rfq,
    rfqoffers: RfqOffers,
    reports: reports,
    useronlinestatu: useronlinestatu,
    userhistorystatu: userhistorystatu,
    memberlogs: memberlogs,
    products: Products,
    posts: Posts,
    schedules: Schedules,
    facebookPosts: FacebookPosts,
    SearchHistory: SearchHistory,
  },
  data() {
    return {
      e1: 1,
      tabpost: 1,
      isPassUpdate: false,
      password_type: false,
      sub1: false,
      overlay: true,
      pageisready: false,
      changePackage: false,
      dialogPass: false,
      dialogPassColor: "primary",
      passLoadingText: "Loading.. Please wait.",
      passLock: false,
      dialog: false,
      loading: true,
      isStore: false,
      formFields: null,
      iswizardActive: true,
      selectedPackage: null,
      onlineAllUsers: [],
      onlineMembers: [],
      historyMembers: [],
      packagesAll: [],
      moment: moment,
      pass: {
        password: "",
        passwordConfirm: "",
      },
      formdata: {
        address: "",
        city_id: null,
        company_name: "",
        country_code: "",
        account_type: "",
        country_id: null,
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        how_find_gowawe: "",
        selected_lang: "",
        wizarddata: null,
      },
      fielddata: {
        values: {},
      },
      packages: [],
      phonecodes: [],
      countries: [],
      cities: [],
      howfindgowawe: [
        {
          id: "Google",
          name: "Google",
        },
        {
          id: "Facebook",
          name: "Facebook",
        },
        {
          id: "Twitter",
          name: "Twitter",
        },
        {
          id: "Youtube",
          name: "Youtube",
        },
        {
          id: "Linkedin",
          name: "Linkedin",
        },
        {
          id: "Friends",
          name: "Friends",
        },
        {
          id: "Other",
          name: "Other",
        },
      ],
      accounttypes: [
        {
          id: "buyer",
          name: "Buyer",
        },
        {
          id: "seller",
          name: "Supplier",
        },
        {
          id: "both",
          name: "Both",
        },
      ],
      selectlocalies: [
        {
          id: "ar",
          name: "عربي",
        },
        {
          id: "tr",
          name: "Türkçe",
        },
        {
          id: "en",
          name: "English",
        },
      ],
      created_at: "",
      businessTypes: null,
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    makeStrongPass() {
      var randomstring = Math.random()
        .toString(36)
        .slice(-8);
      this.pass.password = randomstring;
      this.pass.passwordConfirm = randomstring;
      this.password_type = true;
    },
    seturl: function() {
      window.history.pushState(
        state,
        "",
        "/members/edit/" + this.$route.params.id + "/" + this.el
      );
    },
    accounttypechange: function() {
      axios
        .post(process.env.VUE_APP_API_URL + "member/type-change", {
          member_id: this.$route.params.id,
          type: this.formdata.account_type,
        })
        .then((res) => {
          if (res.data.success) {
            this.dialogPass = true;
            this.dialogPassColor = "success";
            this.passLoadingText =
              "The account type has been successfully changed.";
          }
        })
        .catch((err) => {
          console.log(err);
          this.dialogPass = true;
          this.dialogPassColor = "red";
          this.passLoadingText = "Opps.. an error has occurred. Try again.";
        });

      this.dialogReset();
    },
    savePass: function() {
      this.dialogPass = true;

      if (
        this.pass.password != "" &&
        this.pass.password == this.pass.passwordConfirm
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "member/" +
              this.$route.params.id +
              "/password",
            this.pass
          )
          .then((res) => {
            this.dialogPassColor = "success";
            this.passLoadingText = "Password changed successfully!";
            this.passLock = false;
            this.isPassUpdate = true;
          })
          .catch((err) => {
            this.dialogPassColor = "red";
            this.passLoadingText = "Opps.. an error has occurred. Try again.";
            this.isPassUpdate = false;
          });
      } else {
        this.dialogPassColor = "red";
        this.passLoadingText = "Passwords Required";
      }

      this.dialogReset();
    },
    saveAllData2: function() {
      this.dialogPass = true;
      const fieldata = {
        fielValues: this.fielddata.values,
      };

      const newDataObject = {
        ...this.formdata.wizarddata.model,
        ...fieldata,
      };

      this.dialog = true;
      axios
        .put(
          process.env.VUE_APP_API_URL +
            "member/" +
            this.$route.params.id +
            "/store-info?api_token=" +
            this.token,
          newDataObject
        )
        .then((res) => {
          this.dialogPass = true;
          this.dialogPassColor = "success";
          this.passLoadingText = "All updated successfully!";
        })
        .catch((err) => {
          console.log(err);

          this.dialogPass = true;
          this.dialogPassColor = "red";
          this.passLoadingText = "Opps.. an error has occurred. Try again.";
        });

      this.dialogReset();
    },
    saveAllData: function() {
      this.dialog = true;
      axios
        .put(
          process.env.VUE_APP_API_URL + "member/" + this.$route.params.id,
          this.formdata
        )
        .then((res) => {
          this.dialog = false;
          this.dialogPass = true;
          this.dialogPassColor = "success";
          this.passLoadingText = "All updated successfully!";
        })
        .catch((err) => {
          console.log(err);

          this.dialog = false;
          this.dialogPass = true;
          this.dialogPassColor = "red";
          this.passLoadingText = "Opps.. an error has occurred. Try again.";
        });

      this.dialogReset();
    },
    dialogReset() {
      setTimeout(() => {
        this.dialogPass = false;
        this.passLoadingText = "Loading.. Please wait.";
        this.dialogPassColor = "primary";
      }, 3500);
    },
    getCountry: function() {
      let url =
        process.env.VUE_APP_API_URL +
        "country/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios.post(url).then((res) => {
        res.data.data.data.forEach((item) => {
          this.countries.push({
            id: item.id,
            name: item.name,
            country_code: item.country_code,
          });
        });
      });
    },
    getCities: function() {
      this.cities = [];
      let url =
        process.env.VUE_APP_API_URL +
        "country/" +
        this.formdata.country_id +
        "/cities?api_token=" +
        this.token;
      axios.get(url).then((res) => {
        res.data.data.forEach((item) => {
          this.cities.push({
            id: item.id,
            name: item.name,
          });
        });
        this.loading = false;
      });
    },
    getData: function(id) {
      let url = process.env.VUE_APP_API_URL + "member/" + id;
      axios.get(url).then((res) => {
        const response = res.data;
        this.isStore = response.store ? true : false;
        this.formdata.first_name = response.first_name;
        this.formdata.last_name = response.last_name;
        this.formdata.address = response.address;
        this.formdata.country_code = response.country_code;
        this.formdata.country_id = response.country_id;
        this.formdata.city_id = response.city_id;
        this.formdata.how_find_gowawe = response.how_find_gowawe;
        if (response.user) {
          if (response.user.email) this.formdata.email = response.user.email;
        }
        this.created_at = response.created_at;
        this.formdata.phone = response.phone;
        this.formdata.domain = response.store ? response.store.domain : null;
        this.formdata.selected_lang = response.user.selected_lang;
        this.formdata.is_approved = response.is_approved;
        this.formdata.account_type =
          response.is_seller && response.is_buyer
            ? "both"
            : response.is_seller
            ? "seller"
            : "buyer";
        this.formdata.company_name = response.store?.company_name;

        this.getCities();
        this.getWizard(id);
        this.getBusinessTypes();
      });
    },
    getWizard: function(id) {
      let url = process.env.VUE_APP_API_URL + "wizard/" + id;
      axios
        .get(url)
        .then((res) => {
          this.formdata.wizarddata = res.data.data;
          this.pageisready = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFormFields: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "store/" +
            this.$route.params.id +
            "/fields?api_token=" +
            this.token
        )
        .then((res) => {
          if (res && res.data && res.data.success == false) {
            this.iswizardActive = false;
            return false;
          }

          let resp = res.data.fields;

          this.formFields = resp.filter((item, index) => {
            if (item.form_group_fields) {
              return item.form_group_fields.find((a) => {
                if (a.children) {
                  return a.children.sort((b, c) => {
                    return b.sort - c.sort;
                  });
                }
              });
            }
          });

          this.formFields.forEach((field) => {
            field.form_group_fields.forEach((group) => {
              if (group.multiple == 1) {
                this.fielddata.values[group.id] = group.values;
              } else {
                for (let index = 0; index < group.children.length; index++) {
                  const child = group.children[index];
                  this.fielddata.values[child.id] = child.value;
                }
              }
            });
          });

          //this.fielddata
        });
    },
    getBusinessTypes: function() {
      const fields = [
        {
          field: "lookup_type_id",
          op: "=",
          value: 10,
        },
      ];
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "lookup/filter?page=1&per_page=100" +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: [],
            type: "",
          }
        )
        .then((response) => {
          this.businessTypes = response.data.data.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPhoneCode: function() {
      let url = process.env.VUE_APP_API_URL + "get-all-phone-code";
      axios.get(url).then((res) => {
        this.phonecodes = res.data.data;

        // res.data.data.data.forEach((item) => {
        //   this.phonecodes.push({
        //     id: item.id,
        //     name: item.name,
        //   });
        // });
      });
    },
    getPackageAll: function() {
      const url =
        process.env.VUE_APP_API_URL +
        "packages/filter?page=1&per_page=10000&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.packagesAll = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
    updatePackage: function(e) {
      // selectedPackage

      const dataPackage = {
        member_id: this.$route.params.id,
        price: this.selectedPackage.price,
        subscription_package_id: this.selectedPackage.id,
      };

      axios
        .post(
          process.env.VUE_APP_API_URL + "member/package-change",
          dataPackage
        )
        .then((res) => {
          if (res.data) {
            this.getPackage();
          }
        })
        .catch((err) => console.log(err));
    },
    getPackage: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "packages/member-subscriptions/" +
            this.$route.params.id
        )
        .then((res) => {
          this.packages = res.data.data;
        })
        .catch((err) => console.log(err));

      this.getPackageAll();
    },
  },

  sockets: {
    usersGowaweSet: (data) => {
      // data
    },
  },
  mounted() {
    this.getCountry();
    this.getFormFields();
    this.getData(this.$route.params.id);
    this.getPhoneCode();
    this.getPackage();
    this.e1 = this.$route.params.e1 ?? 1;
    this.sockets.subscribe("usersGowaweSet", (data) => {
      this.onlineAllUsers = data;
      this.onlineMembers = data.filter((a) => {
        if (a.data.memberid == this.$route.params.id) {
          return a;
        }
      });
    });
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style>
.realtimeSignal {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #2acf00;
  display: block;
}
.v-stepper__header {
  height: auto !important;
}
</style>

<template>
  <div>
    <v-container v-if="1 == 2">
      <v-col cols="12">
        <MapChart
          v-if="data"
          :countryData="data"
          highColor="#198cff"
          lowColor="#a0d0ff"
          countryStrokeColor="#d7ebff"
          defaultCountryFillColor="#97cbff"
          :showLegend="true"
          :currencyAdd="false"
          LangUser="en"
        />
      </v-col>
    </v-container>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              #
            </th>
            <th class="text-left">
              User Name
            </th>
            <th class="text-left">
              Phone Number
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Registration Country
            </th>
            <th class="text-left">
              Number of Visits
            </th>
            <th class="text-left">Last Visit Date</th>
            <th class="text-left">Detail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in historymembers" :key="'hs-' + index">
            <td>{{ item.interaction_member.id }}</td>
            <td>
              {{
                item.interaction_member.first_name +
                  " " +
                  item.interaction_member.last_name
              }}
            </td>
            <td>
              +{{ item.interaction_member.country_code }}
              {{ item.interaction_member.phone }}
            </td>
            <td>
              {{ item.interactionUser.email }}
            </td>
            <td>
              <div
                v-html="matchCountry(item.interaction_member.country_id)"
              ></div>
            </td>
            <td>{{ item.number_of_visits }}</td>
            <td>{{ item.updated_at }}</td>
            <td>
              <v-btn
                elevation="0"
                link
                color="primary"
                :to="
                  '/members/edit/' +
                    item.interaction_member.id +
                    '/detail/visit/' +
                    item.id
                "
                ><v-icon>
                  mdi-history
                </v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import MapChart from "vue-chart-map";
import axios from "axios";

export default {
  name: "ReportsTabcontent1",
  props: ["countries"],
  components: {
    MapChart: MapChart,
  },
  data: function() {
    return {
      data: null,
      historymembers: [],
    };
  },
  methods: {
    getList() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "member-visits/" + this.$route.params.id
        )
        .then((res) => {
          this.historymembers = res.data.visits.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    matchCountry(id) {
      if (this.countries.find((a) => a.id == id)) {
        let cu = this.countries.find((a) => a.id == id);
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
  created() {
    this.getList();
  },
  watch: {
    countries(newVal, oldVal) {
      let x = {};
      newVal.forEach((item) => {
        x[item.country_code] = Math.floor(Math.random() * 100) + 1;
      });
      this.data = x;
    },
  },
};
</script>

<template>
  <div class="table_wrapper">
    <vc-calendar
      class="custom-calendar max-w-full"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
            >
              {{ attr.customData.title }}
            </p>
          </div>
        </div>
      </template>
    </vc-calendar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  name: "Schedules",
  data() {
    return {
      moment: moment,
      loading: true,
      token: localStorage.getItem("authToken"),
      month: new Date().getMonth(),
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      attributes: [],
    };
  },
  methods: {
    getData() {
      axios
        .get(
          "https://gowawe.com/api/admin/post-schedule-api/" +
            this.$route.params.id +
            "?my=false&all=false&i_added=false&is_posted=true"
        )
        .then((res) => {
          this.attributes = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    this.getData();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style>
.custom-calendar.vc-container {
  border-radius: 0;
  width: 100%;
}

.vc-day[data-v-4420d078] {
  position: relative;
  min-height: 32px;
  z-index: 1;
}

.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px;
  text-align: left;
  height: 150px;
  min-width: 80px;
  overflow-x: hidden;
  background-color: #fff;
}

.custom-calendar.vc-container .vc-day::-webkit-scrollbar {
  width: 3px;
}
.custom-calendar.vc-container .vc-day::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 8px;
}
.custom-calendar.vc-container .vc-day::-webkit-scrollbar-track {
  background: #fff;
}

.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: 1px solid #b8c2cc;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: 1px solid #eaeaea;
}

.data_date {
  background-color: #007cd5;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.25;
  border-radius: 3px;
  font-weight: 500;
  height: 35px;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>

<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="(th, index) in items.children"
              :key="index"
            >
              {{ th.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, indis) in items.values" :key="indis">
            <td v-for="(val, key) in item.values" :key="key">
              <v-col cols="12">
                <v-text-field
                  v-if="
                    key != 294 &&
                      key != 158 &&
                      key != 160 &&
                      key != 161 &&
                      key != 162
                  "
                  hide-details="auto"
                  v-model="val.value"
                  solo
                ></v-text-field>
                <countryandcity
                  :item="val"
                  :islookup="true"
                  :countries="countries"
                  :cities="cities"
                  v-else-if="key == 158"
                ></countryandcity>

                <v-row v-else>
                  <v-col cols="6" class="mx-auto">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        max-width="100"
                        max-height="100"
                        class="mx-auto"
                        :elevation="hover ? 12 : 2"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-img :src="val.value">
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                              style="height: 100%;"
                            >
                              <a :href="val.value" target="_blank">
                                <v-icon color="white">
                                  mdi-image-search
                                </v-icon>
                              </a>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="12">
                    <input
                      type="file"
                      class="d-none"
                      :ref="'file-' + key"
                      v-on:change="fileUpload($event, val)"
                    />
                    <v-btn
                      text
                      color="primary"
                      v-on:click="selectInput(key)"
                      block
                      >Select</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import axios from "axios";
import CountryandCity from "./CountryandCity.vue";
export default {
  name: "LookupGroup",
  props: ["items", "countries", "cities"],
  components: {
    countryandcity: CountryandCity,
  },
  data() {
    return {
      data: [],
      token: localStorage.getItem("authToken"),
      loading: true,
    };
  },
  methods: {
    selectInput(indis) {
      const kk = Object.keys(this.$refs);
      const vv = Object.values(this.$refs);
      const elem = vv[kk.indexOf("file-" + indis)][0];

      console.log(document.querySelector(elem[0]));
    },
    fileUpload(filesData, itemValue) {
      if (filesData && filesData.target.files.length > 0) {
        this.uploader(filesData.target.files)
          .then((res) => {
            itemValue.value = res.data.file_paths[0];
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async uploader(data) {
      try {
        let formDataClass = new FormData();
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            formDataClass.append("file[]", data[i]);
          }
        } else {
          formDataClass.append("file", data);
        }
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "upload",
          formDataClass,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>

<template>
  <div>
    <v-select
      item-text="name"
      item-value="id"
      :loading="loading"
      :items="data"
      persistent-hint
      :label="item.label"
      v-model="selectValue"
    ></v-select>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "LookupControl",
  props: {
    item: {
      required: true,
    },
  },
  created() {
    this.getLookup(this.item)
      .then((res) => {
        this.data = res;
        this.loading = false;
      })
      .catch((error) => console.log(error));
  },
  data() {
    return {
      data: [],
      token: localStorage.getItem("authToken"),
      loading: true,
    };
  },
  computed: {
    selectValue: {
      get: function() {
        return Number(this.item.value.value);
      },
      set: function(val) {
        this.item.value.value = val.toString();
      },
    },
  },
  methods: {
    async getLookup(item) {
      let val = item.lookup_type_id;
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "lookup/filter?page=1&per_page=100&api_token=" +
            this.token,
          {
            fields: [
              {
                field: "lookup_type_id",
                op: "=",
                value: val,
              },
            ],
          }
        )
        .then(function(response) {
          return response.data.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

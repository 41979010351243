<template>
  <div class="table_wrapper">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>RFQ Offer List</v-toolbar-title>

          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="success"
            class="ml-2"
            v-on:click="selectedAction('approve')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Approve Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="error"
            class="ml-2"
            v-on:click="selectedAction('reject')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Reject Selected ({{ selected.length }})
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.c3`]="{ item }">
        <span v-for="(x, i) in item.c3" :key="'x-' + i">
          <v-chip small>{{ x.product_name }}</v-chip>
        </span>
      </template>

      <template v-slot:[`item.c5`]="{ item }">
        <v-chip :color="'white'" dark>
          <router-link :to="'/rfq-offers/' + item.item.member_id">{{
            item.c5
          }}</router-link>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'/rfq-offers/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "RfqOffers",
  data() {
    return {
      dialog: false,
      selected: [],
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c2", sortable: false },
        { text: "Products", value: "c3", sortable: false },
        { text: "Member", value: "c5", sortable: false },
        { text: "Description", value: "c6", sortable: false },
        { text: "Quantity", value: "c7", sortable: false },
        { text: "Date", value: "c8", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.item;
        });

        let url = "";

        if (type == "delete") {
          url = process.env.VUE_APP_API_URL + "quotation-offer/bulkdelete";
        } else if (type == "approve") {
          url = process.env.VUE_APP_API_URL + "quotation-offer/approve";
        } else if (type == "reject") {
          url = process.env.VUE_APP_API_URL + "quotation-offer/reject";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },

    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      const deletedData = this.dialogAction.data.map((b) => {
        return b.id;
      });

      axios
        .post(this.dialogAction.url, {
          id: deletedData,
          api_token: this.token,
        })
        .then((res) => {
          this.getDataFromApi(this.activeTab);
        })
        .catch((err) => {
          console.log(err);
        });

      this.dialogAction.data.forEach((item) => {
        const dataToX = item.member_id;

        if (!dataToX) {
          return false;
        }

        if (this.dialogAction.type == "approve") {
          let notifyMessage = {
            to: dataToX,
            title: "New RFQ Notify!",
            name: "Great!",
            message: {
              text: "Your rfq has been approved.",
            },
          };
          this.$socket.emit("sendNotify", notifyMessage);
        } else if ((this.dialogAction.type = "reject")) {
          let notifyMessage = {
            to: dataToX,
            title: "New RFQ Notify!",
            name: "We are sorry..",
            message: {
              text: "Your rfq has been rejected.",
            },
          };
          this.$socket.emit("sendNotify", notifyMessage);
        }
      });
    },

    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },

    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: index + 1,
            c2: item.id,
            c3: item.quotation_req_offer_product,
            c5: item.member ? item.member.name : "",
            c6: item.description,
            c7: item.quotation_request_offers_count,
            c8: item.created_at,
            actions: null,
            member: item.member,
            category: item.category,
            item: item,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    async getDesserts() {
      const fields = [];

      if (this.$route.params.id) {
        fields.push({
          field: "member_id",
          op: "=",
          value: this.$route.params.id,
        });
      }

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            "quotation-offer/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields: fields,
          }
        );
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.getDataFromApi(this.activeTab);
      }
    );
  },
};
</script>

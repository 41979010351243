<template>
  <div>
    <v-layout justify-space-between>
      <v-col cols="8">
        <v-card class="p-3" elevation="2">
          <v-card-title>Store Statu</v-card-title>
          <v-card-text>Do you want to confirm the store?</v-card-text>
          <v-divider></v-divider>
          <v-switch
            label="Is Business Category Approved"
            inset
            v-model="formdata.wizarddata.model.is_business_category_approved"
          ></v-switch>
          <v-switch
            label="Is Approved"
            inset
            v-model="formdata.is_approved"
          ></v-switch>
          <v-switch
            label="Is Verified"
            inset
            v-model="formdata.wizarddata.model.is_store_verified"
          ></v-switch>
          <v-switch
            label="Is Selected"
            inset
            v-model="formdata.wizarddata.model.is_selected"
          ></v-switch>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card :loading="loading" max-width="374">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            height="170"
            :src="formdata.wizarddata.model.cover_photo"
            class="overlay_cover"
          >
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-spacer></v-spacer>
            </v-app-bar>
            <v-card-title class="white--text mt-6">
              <v-avatar size="60">
                <img alt="user" :src="formdata.wizarddata.model.logo" />
              </v-avatar>
              <div class="flex d-flex align-items-start pl-2">
                <div>
                  <span class="store_name"
                    >{{ formdata.wizarddata.model.company_name }}
                  </span>
                  <v-icon
                    color="light-blue accent-3"
                    v-if="formdata.wizarddata.model.is_store_verified"
                    >mdi-shield-check</v-icon
                  >
                  <div class="store_chips">
                    <v-chip
                      v-for="(a, i) in businessTypesLimited"
                      :key="i"
                      x-small
                      class="py-0 px-1"
                      >{{ a.name }}</v-chip
                    >
                  </div>
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                v-if="formdata.wizarddata.model.domain"
                color="white"
                fab
                dark
                icon
                v-on:click="openStore"
              >
                <v-icon>mdi-arrow-top-right</v-icon>
              </v-btn>
            </v-card-title>
          </v-img>

          <v-card-title>{{
            formdata.wizarddata.model.member.name
          }}</v-card-title>

          <v-card-text>
            <v-layout align="center" wrap class="m-0">
              <v-col cols="12" class="p-1 mb-2">
                <v-icon>mdi-phone</v-icon>
                <span class="grey--text">
                  {{ formdata.wizarddata.model.member.phone }}
                </span>
              </v-col>
              <v-col cols="12" class="p-1 mb-2">
                <v-icon>mdi-map-marker</v-icon>
                <span class="grey--text">
                  {{ formdata.wizarddata.model.address.country.name }}
                  {{ formdata.wizarddata.model.address.city_name }}
                </span>
              </v-col>
              <v-col cols="12" class="p-1 mb-2">
                <v-icon>mdi-clipboard-text-clock</v-icon>
                <span class="grey--text">
                  {{ moment(formdata.wizarddata.model.created_at).fromNow() }}
                  created
                </span>
              </v-col>
            </v-layout>

            <v-card-actions>
              <h6 class="pt-3">Description</h6>
              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <div>{{ formdata.wizarddata.model.description }}</div>
                <v-divider></v-divider>
                <div>{{ formdata.wizarddata.model.description_en }}</div>
                <v-divider></v-divider>
                <div>{{ formdata.wizarddata.model.description_ar }}</div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Tabcontent7",
  props: ["formdata"],
  data() {
    return {
      show: false,
      moment: moment,
      formfields: [],
      loading: false,
      token: localStorage.getItem("authToken"),
    };
  },
  computed: {
    businessTypesLimited: function() {
      return this.formdata.wizarddata.companyBusinessTypes.slice(0, 2);
    },
  },
  methods: {
    openStore() {
      window.open(
        "https://" + this.formdata.wizarddata.model.domain + ".gowawe.com",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.store_name {
  max-width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}
.store_chips {
  position: relative;
  line-height: 25px;
}
.overlay_cover:after {
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(transparent, black);
  z-index: -1;
  height: 80px;
}
</style>

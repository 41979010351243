<template>
  <storeverifyshow></storeverifyshow>
</template>

<script>
import StoreVerifyShow from "../../Pages/StoreVerifyShow.vue";
export default {
  name: "Tabcontent6",
  data() {
    return {
      norules: [],
      formfields: [],
      rules: [(v) => !!v || "Field is required"],
      token: localStorage.getItem("authToken"),
    };
  },
  components: {
    storeverifyshow: StoreVerifyShow,
  },
  methods: {},
  created() {
    //console.log(this.formdata);
  },
};
</script>

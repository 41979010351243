<template>
  <div class="table_wrapper">
    <div class="wrapper_post_list" v-if="itemsData.length != 0">
      <v-card
        class="mx-auto mb-5"
        max-width="500"
        v-for="(item, index) in itemsData"
        :key="index"
      >
        <v-layout wrap class="p-3 pb-0" align-center>
          <v-avatar width="50" height="50" class="mr-2">
            <v-img :src="item.store.logo"></v-img>
          </v-avatar>
          <div class="card_company">
            <v-flex>
              <h6 class="mb-0">
                {{ item.store.company_name }}
              </h6>
              <v-col cols="12" class="p-0">
                <v-layout align-center>
                  <v-img
                    :src="item.store.company_flag"
                    width="20"
                    height="20"
                    max-width="20"
                    contain
                  ></v-img>
                  <span class="px-1">{{ item.store.country.name }}</span>
                  <span class="px-1" v-if="item.store.since"
                    >{{ item.store.since.since }}
                    {{ item.store.since.unit }}</span
                  >
                </v-layout>
              </v-col>
            </v-flex>
          </div>
        </v-layout>
        <v-card-title class="body-1" v-if="item.post_text">
          {{ item.post_text }}
        </v-card-title>

        <div
          class="product_image_src"
          v-if="toArray(item.photos)"
          v-bind:class="{ 'mt-3': !item.post_text }"
        >
          <lightbox :items="toArray(item.photos)"></lightbox>
        </div>

        <v-card-subtitle v-bind:class="{ 'mt-3': item.post_text }">
          <v-layout justify-space-around align-center>
            <div
              class="text-center _card_buttons"
              v-if="item.product_id || item.id"
            >
              POST ID
              <i class="d-block w-100">{{
                type == "facebook" ? item.id : item.product_id
              }}</i>
            </div>
            <div class="text-center _card_buttons">
              Is Shared
              <i class="d-block w-100">No</i>
            </div>
            <div class="text-center _card_buttons" v-if="item.created_at">
              Created at
              <i class="d-block w-100"> {{ item.created_at }}</i>
            </div>
          </v-layout>
        </v-card-subtitle>

        <v-card-actions>
          <v-layout align-center justify-space-between>
            <v-col cols="6">
              <h6>Platform</h6>
              <v-icon color="primary" v-if="type && type == 'facebook'">
                mdi-facebook
              </v-icon>
              <span v-else>Gowawe</span>
            </v-col>
            <v-col cols="6">
              <v-layout wrap>
                <v-avatar width="50" height="50" class="mr-2">
                  <v-img
                    src="https://gowawe.com/images/gowawe-tr-logo.jpg"
                  ></v-img>
                </v-avatar>
                <div class="_platform_area">
                  <h5 class="mb-0">Gowawe.com Türkiye</h5>
                  <span>turkish post</span>
                </div>
              </v-layout>
            </v-col>
          </v-layout>
        </v-card-actions>
      </v-card>
    </div>
    <div class="wrapper_post_register" v-else>
      <v-alert color="primary" type="info">
        Post feature is disabled for this user. Would you like to activate?
        <v-btn color="green" class="ml-3" dark>
          <v-icon>mdi-check</v-icon>
          Yes, activate.
        </v-btn>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  name: "Posts",
  props: {
    type: {
      required: false,
      default: "gowawe",
      type: String,
    },
    isx: {
      required: false,
      default: "all",
      type: String,
    },
  },
  data() {
    return {
      mfieldsFilter: false,
      dialog: false,
      dialog2: false,
      moment: moment,
      storeItem: null,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "Post ID", value: "c1", sortable: false },
        { text: "Product ID", value: "c2", sortable: false },
        { text: "Post Text", value: "c3", sortable: false },
        { text: "Moment", value: "c4", sortable: false },
        { text: "Created At", value: "c5", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 10000,
      itemsData: [],
      mfields: [],
      timer: null,
      store: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },
  methods: {
    toArray(data) {
      if (data) {
        return JSON.parse(JSON.stringify(data));
      }

      return null;
    },
    async getDesserts() {
      let fields = this.mfields;
      const sort = [
        {
          dir: "desc",
          field: "created_at",
        },
      ];

      try {
        if (this.type == "facebook") {
          if (this.isx == "all") {
            let response = await axios.get(
              process.env.VUE_APP_API_URL +
                "post-schedule-api/" +
                this.$route.params.id +
                "?my=true&all=false&i_added=false&is_posted=false"
            );

            this.store = response.data.data;

            return response.data.data;
          } else {
            let response = await axios.get(
              process.env.VUE_APP_API_URL +
                "post-schedule-api/" +
                this.$route.params.id +
                "?my=false&all=false&i_added=true&is_posted=false"
            );

            this.store = response.data.data;
            return response.data.data;
          }
        } else {
          let response = await axios.post(
            process.env.VUE_APP_API_URL +
              "post/filter?page=" +
              this.page +
              "&per_page=" +
              this.perPage +
              "&api_token=" +
              this.token,
            {
              fields,
              sort: sort,
              type: "",
            }
          );
          return response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    this.mfields = [];
    if (this.$route.params.id) {
      this.mfields.push({
        field: "store_id",
        op: "=",
        value: this.$route.params.id,
      });
    }

    this.getDesserts()
      .then((res) => {
        // console.table(res);
        if (res.data) {
          this.itemsData = res.data;
        } else {
          this.itemsData = res;
        }
      })
      .catch((err) => {});
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style>
._card_buttons {
  border-radius: 7px;
  background: #ffffff;
  text-align: center;
  padding: 4px 0;
  margin: 0 1px;
  font-weight: 500;
  font-size: 11px;
  white-space: nowrap;
  width: 30%;
  color: #5c5c5c;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 2px -2px #000;
}
._platform_area h5 {
  font-size: 16px;
}
.product_image_src {
  height: 400px;
  max-height: 500px;
}
.product_image_src .lb .lb-grid,
.product_image_src .lb {
  width: 100%;
  height: 100%;
  direction: ltr;
}
.product_image_src .lb .lb-grid,
.product_image_src .lb {
  width: 100%;
  height: 100%;
  direction: ltr;
}
</style>

<template>
  <div>
    <v-card class="p-3">
      <v-card-title class="justify-center">Sub domain the Store </v-card-title>
      <v-layout row="false" column="true">
        <v-col cols="3" class="mx-auto right_text">
          <v-text-field
            prepend-icon="mdi-web"
            regular
            required
            justify-right
            v-model="formdata.wizarddata.model.domain"
            suffix=".gowawe.com"
            :loading="loading"
            :disabled="disabled"
            v-on:input="domainSearch"
            :rules="rules"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="mx-auto">
          <h6 class="text-center">
            <span :class="domain_color">{{
              formdata.wizarddata.model.domain
            }}</span
            >.gowawe.com
          </h6>
          <p class="subtitle-1 text-center" :class="domain_color">
            <v-icon class="mr-2" :class="domain_color" v-if="is_search">{{
              domain_is_available
            }}</v-icon
            >{{ domain_check_message }}
          </p>
        </v-col>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import RulesClass from "../../../services/rules";
export default {
  name: "Tabcontent5",
  props: ["formdata"],
  data() {
    return {
      loading: false,
      disabled: false,
      token: localStorage.getItem("authToken"),
      timer: null,
      domain_is_available: "mdi-help-circle",
      domain_color: "blue--text",
      is_search: false,
      domain_check_message: "",
      requireds: {
        allowSpaces: false,
        max: 20,
        min: 5,
      },
    };
  },
  computed: {
    rules() {
      const rulesClass = new RulesClass();
      return rulesClass.req(this.requireds);
    },
  },
  mounted() {
    //console.log(this.formdata);
  },
  methods: {
    domainSearch: function() {
      if (
        this.formdata.wizarddata.model.domain.trim().length >=
          this.requireds.min &&
        this.formdata.wizarddata.model.domain.trim().length <=
          this.requireds.max
      ) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
          this.is_search = false;
          this.domain_color = "blue--text";
          this.domain_check_message = "Checking..";
        }
        this.timer = setTimeout(() => {
          this.disabled = true;
          this.loading = true;

          this.is_search = true;

          const url =
            "https://gowawe.com/api/admin/check-domain?api_token=" + this.token;
          axios
            .post(url, {
              domain: this.formdata.wizarddata.model.domain,
            })
            .then((res) => {
              this.domain_check_message = res.data.message;

              if (res.data.statu == "success") {
                this.domain_is_available = "mdi-check-circle";
                this.domain_color = "green--text";
              } else {
                this.domain_is_available = "mdi-alert-circle";
                this.domain_color = "red--text";
              }

              this.disabled = false;
              this.loading = false;
            })
            .catch((err) => console.log(err));
        }, 700);
      }
    },
  },
};
</script>

<style>
.right_text .v-text-field__slot input {
  text-align: right;
}
</style>

<template>
  <div>
    <v-card class="p-3 ">
      <v-card-title class="justify-center"
        >Enter your Google Analytics ID format example UA-000000-2</v-card-title
      >
      <v-row>
        <v-col cols="3" class="mx-auto center_text">
          <v-text-field
            :rules="rules"
            v-model="formdata.wizarddata.model.ga_code"
            regular
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import RulesClass from "../../../services/rules";
export default {
  name: "Tabcontent4",
  props: ["formdata"],
  data() {
    return {
      token: localStorage.getItem("authToken"),
      requireds: {
        allowSpaces: false,
        max: 20,
        min: 8,
        regex: [/(UA|YT|MO)-\d+-\d+/i],
      },
    };
  },
  methods: {},
  mounted() {
    //console.log(this.formfields);
  },
  computed: {
    rules() {
      const rulesClass = new RulesClass();
      return rulesClass.req(this.requireds);
    },
  },
};
</script>

<style>
.center_text .v-text-field__slot input {
  text-align: center;
}
</style>

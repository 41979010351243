<template>
  <div class="p-3 mb-5">
    <v-card>
      <v-tabs background-color="primary" dark grow v-model="model">
        <v-tab v-for="(x, i) in tabs" :key="i" :href="`#tab-${i}`">{{
          x.name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="model">
        <v-tab-item v-for="(x, i) in tabs" :key="i" :value="`tab-${i}`">
          <v-card flat class="p-2">
            <h6>{{ x.name }}</h6>
            <keep-alive>
              <wizardtabs
                v-if="formdata"
                :name="x.name"
                :businesstypes="businesstypes"
                :formdata="formdata"
                :formfields="formfields"
                :fielddata="fielddata"
                :countries="countries"
                :cities="cities"
                v-bind:is="'tabcontent' + i"
              ></wizardtabs>
            </keep-alive>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Tabcontent1 from "./Wizards/Tabcontent1.vue";
import Tabcontent2 from "./Wizards/Tabcontent2.vue";
import Tabcontent3 from "./Wizards/Tabcontent3.vue";
import Tabcontent4 from "./Wizards/Tabcontent4.vue";
import Tabcontent5 from "./Wizards/Tabcontent5.vue";
import Tabcontent6 from "./Wizards/Tabcontent6.vue";
import Tabcontent7 from "./Wizards/Tabcontent7.vue";

export default {
  name: "storewizard",
  props: [
    "formdata",
    "businesstypes",
    "countries",
    "cities",
    "fielddata",
    "formfields",
  ],
  data: function() {
    return {
      data: null,
      businesstypesNew: [],
      model: "tab-0",
      tabs: [
        {
          name: "Business Categories",
        },
        {
          name: "Store Info",
        },
        {
          name: "Photos",
        },
        {
          name: "Google Analytics",
        },
        {
          name: "Domain",
        },
        {
          name: "Store Verified",
        },
        {
          name: "Result",
        },
      ],
    };
  },
  components: {
    tabcontent0: Tabcontent1, // Business Category
    tabcontent1: Tabcontent2, // Store Info
    tabcontent2: Tabcontent3, // Photos
    tabcontent3: Tabcontent4, // Google Analytics
    tabcontent4: Tabcontent5, // Domain
    tabcontent5: Tabcontent6, // Store Verified
    tabcontent6: Tabcontent7, // Result
  },
};
</script>

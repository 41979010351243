<template>
  <nav>
    <ul class="pagination">
      <!-- <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click="previousPage"
        >
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        :class="{ active: currentPage === pageNumber }"
      >
        <a class="page-link" href="#" @click="goToPage(pageNumber)">{{
          pageNumber
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" aria-label="Next" @click="nextPage">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li> -->

      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click.prevent="previousPage"
        >
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="pageNumber in visiblePages"
        :key="pageNumber"
        :class="{ active: currentPage === pageNumber }"
      >
        <a class="page-link" href="#" @click.prevent="goToPage(pageNumber)">{{
          pageNumber
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          @click.prevent="nextPage"
        >
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>

      <!-- <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click="previousPage"
        >
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="pageNumber in visiblePages"
        :key="pageNumber"
        :class="{ active: currentPage === pageNumber }"
      >
        <a class="page-link" href="#" @click="goToPage(pageNumber)">{{
          pageNumber
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" aria-label="Next" @click="nextPage">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li> -->
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    maxVisiblePages: {
      type: Number,
      default: 10
    }
  },
  computed: {
    visiblePages() {
      const half = Math.floor(this.maxVisiblePages / 2);
      const start = Math.max(1, this.currentPage - half);
      const end = Math.min(this.totalPages, start + this.maxVisiblePages - 1);

      const pages = [];
      if (start > 1) {
        pages.push(1);
        if (start > 2) {
          pages.push("...");
        }
      }
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      if (end < this.totalPages) {
        if (end < this.totalPages - 1) {
          pages.push("...");
        }
        pages.push(this.totalPages);
      }
      return pages;
    }
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit("page-changed", this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("page-changed", this.currentPage + 1);
      }
    },
    goToPage(pageNumber) {
      if (pageNumber !== this.currentPage) {
        this.$emit("page-changed", pageNumber);
      }
    }
  }
};
</script>

<template>
  <div>
    <v-card class="p-3">
      <v-card-title
        v-bind:style="{
          backgroundImage: 'url(' + formfields.background + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }"
      >
        <div class="overlayx">
          <v-img :src="formfields.icon" max-width="30" class="mr-2"></v-img>
          <span>{{ formfields.label }}</span>
        </div>
      </v-card-title>

      <div
        v-for="(group, index) in formfields.form_group_fields"
        :key="'row' + index"
      >
        <v-row v-if="!group.multiple" class="justify-start mt-0">
          <v-col cols="12">
            <v-card-title>{{ group.label }}</v-card-title></v-col
          >
          <v-col
            :id="item.id"
            v-for="(item, index) in group.children"
            :key="'group_' + index"
            class="p-3"
            :cols="
              item.type == 'lookup_checkboxes' || item.type == 'lookup_numeric'
                ? '12'
                : '6'
            "
          >
            <div v-if="item.type == 'text'">
              <v-text-field
                v-if="item.multiple == 0"
                :label="item.label"
                hide-details="auto"
                v-model="item.value.value"
                :rules="item.required == 1 ? rules : norules"
              ></v-text-field>
              <v-layout wrap v-else>
                <v-col
                  cols="12"
                  md="12"
                  lg="6"
                  xl="3"
                  v-for="(x, i) in JSON.parse(item.value.value)"
                  :key="i"
                >
                  <v-text-field
                    :label="item.label"
                    hide-details="auto"
                    :type="'number'"
                    :value="x"
                    v-on:input="getset($event, item, i)"
                    :rules="item.required == 1 ? rules : norules"
                  ></v-text-field>
                </v-col>
              </v-layout>
            </div>
            <div v-else-if="item.type == 'number'">
              <v-text-field
                :label="item.label"
                hide-details="auto"
                :type="'number'"
                v-model="item.value.value"
                :rules="item.required == 1 ? rules : norules"
              ></v-text-field>
            </div>
            <div v-else-if="item.type == 'photo'">
              <v-row>
                <v-col cols="6" class="mx-auto">
                  <v-carousel
                    width="250"
                    height="250"
                    v-if="item.value.value.length > 0"
                  >
                    <v-carousel-item
                      v-for="(image, index) in photos(item.value.value)"
                      :key="index"
                      :src="image"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    :label="item.label"
                    outlined
                    dense
                    multiple
                    v-on:change="fileUpload($event, item)"
                    :rules="item.required == 1 ? rules : norules"
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="item.type == 'lookup'">
              <lookupcontrol :item="item"></lookupcontrol>
            </div>
            <div v-else-if="item.type == 'lookup_checkboxes'">
              <lookupcheckbox :item="item"></lookupcheckbox>
            </div>
            <div v-else-if="item.type == 'lookup_numeric'">
              <lookupnumerica :item="item"></lookupnumerica>
            </div>
            <div v-else-if="item.type == 'country_city'">
              <countryandcity
                :item="item.value"
                :countries="countries"
                :cities="cities"
              ></countryandcity>
            </div>
          </v-col>
        </v-row>

        <v-row v-else-if="group.multiple" class="justify-start mt-0">
          <v-card-title>{{ group.label }}</v-card-title>
          <v-card-text>{{ group.help_text }}</v-card-text>

          <v-col cols="12">
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        v-for="(field, index) in group.children"
                        :key="index"
                      >
                        {{ field.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="fielddata.values && group.children">
                    <tr
                      v-for="(val, keyx) in fielddata.values[group.id]"
                      :key="keyx"
                    >
                      <td v-for="(field, keyy) in group.children" :key="keyy">
                        <div v-if="field.type == 'text'">
                          <v-text-field
                            :value="val.values[field.id].value"
                            v-on:input="setvalue($event, val, field)"
                          ></v-text-field>
                        </div>
                        <div v-if="field.type == 'country_city'">
                          <countryandcity
                            :item="val.values[field.id]"
                            :countries="countries"
                            :cities="cities"
                          ></countryandcity>
                        </div>
                        <div v-if="field.type == 'number'">
                          <v-text-field
                            type="number"
                            :value="val.values[field.id].value"
                          ></v-text-field>
                        </div>
                        <div v-if="field.type == 'date'">
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="val.values[field.id].value"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="val.values[field.id].value"
                                label="Picker in dialog"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="val.values[field.id].value"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.dialog[0].save(
                                    val.values[field.id].value
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </div>
                        <div v-if="field.type == 'photo'">
                          <v-layout wrap justify-center class="w-100">
                            <v-col cols="12" class="m-0 p-0 text-center">
                              <v-avatar size="100">
                                <v-img
                                  :src="
                                    val.values[field.id].value
                                      ? val.values[field.id].value
                                      : 'https://via.placeholder.com/100'
                                  "
                                  max-width="100%"
                                  max-height="100%"
                                ></v-img>
                              </v-avatar>
                              <a
                                v-if="val.values[field.id].value"
                                class="text-center d-block w-100"
                                :href="val.values[field.id].value"
                                target="_blank"
                                >view
                              </a>
                            </v-col>
                            <v-col cols="12">
                              <v-file-input
                                :label="field.label"
                                dense
                                v-on:change="
                                  fileUpload($event, val.values[field.id])
                                "
                              ></v-file-input>
                            </v-col>
                          </v-layout>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import LookupControl from "../../Lookups/LookupControl.vue";
import LookupCheckbox from "../../Lookups/LookupCheckbox.vue";
import LookupNumerica from "../../Lookups/LookupNumerica.vue";
import LookupGroup from "../../Lookups/LookupGroup.vue";
import CountryandCity from "../../Lookups/CountryandCity.vue";

export default {
  name: "TabSubcontent1",
  props: ["formfields", "countries", "cities", "fielddata"],
  components: {
    lookupcontrol: LookupControl,
    lookupcheckbox: LookupCheckbox,
    lookupnumerica: LookupNumerica,
    lookupgroup: LookupGroup,
    countryandcity: CountryandCity,
  },
  data() {
    return {
      modal: false,
      inputValue: [],
      norules: [],
      rules: [(v) => !!v || "Field is required"],
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    getset(val, item, indis) {
      let part = JSON.parse(item.value.value);
      part[Number(indis)] = val;

      item.value.value = JSON.stringify(part);
    },
    photos(item) {
      if (typeof item == "string") {
        return JSON.parse(item);
      } else {
        return item;
      }
    },
    setvalue: function(event, val, field) {
      return (val.values[field.id].value = event);
    },
    fileUpload(filesData, item) {
      if (filesData) {
        this.uploader(filesData)
          .then((res) => {
            if (item.value && item.value.value) {
              res.data.file_paths.forEach((path) => {
                item.value.value = JSON.parse(item.value.value);
                item.value.value.push(path);
              });
            } else {
              item.value = res.data.file_paths[0];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async uploader(data) {
      try {
        let formDataClass = new FormData();
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            formDataClass.append("file[]", data[i]);
          }
        } else {
          formDataClass.append("file", data);
        }
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "upload",
          formDataClass,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>

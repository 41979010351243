<template>
  <div>
    <p>You can edit: {{ name }}</p>

    <v-card v-if="formdata">
      <v-tabs background-color="green" dark grow v-model="model">
        <v-tab key="00" :href="`#tab-00`">Company Info</v-tab>
        <v-tab
          v-for="(x, i) in formdata.wizarddata.tabs"
          :key="i + 1"
          :href="`#tab-${i + 1}`"
          >{{ x.label }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="model">
        <v-tab-item value="tab-00">
          <v-card flat class="p-2">
            <v-form v-model="valid">
              <v-container>
                <keep-alive>
                  <wizardsubtabs
                    v-bind:is="'tabsubcontent0'"
                    :formdata="formdata"
                    :fielddata="fielddata"
                    :countries="countries"
                    :cities="cities"
                  ></wizardsubtabs>
                </keep-alive>
              </v-container>
            </v-form>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-for="(x, i) in formdata.wizarddata.tabs"
          :key="i + 1"
          :value="`tab-${i + 1}`"
        >
          <v-card flat class="p-2">
            <v-form v-model="valid">
              <v-container v-if="formfields && formfields.length > 0">
                <keep-alive>
                  <wizardsubtabs
                    v-bind:is="'tabsubcontent1'"
                    :formfields="formfields[i]"
                    :fielddata="fielddata"
                    :countries="countries"
                    :cities="cities"
                  ></wizardsubtabs>
                </keep-alive>
              </v-container>
            </v-form>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import TabSubcontent0 from "./SubWizard/TabSubcontent0.vue";
import TabSubcontent1 from "./SubWizard/TabSubcontent1.vue";

export default {
  name: "Tabcontent2",
  props: {
    name: {
      required: true,
    },
    formdata: {
      required: false,
    },
    formfields: {
      required: false,
    },
    fielddata: {
      required: false,
    },
    countries: {
      required: true,
    },
    cities: {
      required: true,
    },
  },
  data: function() {
    return {
      valid: false,
      data: null,
      model: "tab-0",
      token: localStorage.getItem("authToken"),
    };
  },
  created() {
    //console.log(this.formdata);
  },
  components: {
    tabsubcontent0: TabSubcontent0,
    tabsubcontent1: TabSubcontent1,
  },
};
</script>

<style>
.overlayx {
  background: linear-gradient(90deg, white, transparent);
  width: 100%;
  padding: 0.5em;
  border-radius: 3px;
  color: #000;
  display: flex;
}
</style>

<template>
  <div class="table_wrapper">
    <div class="tab_wrapper d-flex flex-wrap w-100 align-items-center mb-2">
      <v-btn
        v-on:click="getDataFromApi(0)"
        color="primary"
        :loading="tabIndis[0].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[0].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(1)"
        color="success"
        :loading="tabIndis[1].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[1].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(2)"
        color="error"
        :loading="tabIndis[2].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[2].name }}</span>
      </v-btn>
    </div>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      item-key="id"
      :search="search"
      :items-per-page="perPage"
      show-select
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>RFQ List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title>{{ tabIndis[activeTab].name }}</v-toolbar-title>
          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="success"
            class="ml-2"
            v-on:click="selectedAction('approve')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Approve Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="error"
            class="ml-2"
            v-on:click="selectedAction('reject')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Reject Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            v-on:keyup="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`item.c6`]="{ item }">
        <v-chip :color="'white'" dark>
          <router-link :to="'/members/edit/' + item.item.member_id + '/3'">{{
            item.c6
          }}</router-link>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'/rfq/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
              :disabled="actionLock"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip top v-if="activeTab == 0 || activeTab == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'approved')"
              color="success"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
              :disabled="actionLock"
            >
              <v-icon small>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>Approve</span>
        </v-tooltip>

        <v-tooltip top v-if="activeTab == 0 || activeTab == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'rejected')"
              color="error"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
              :disabled="actionLock"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :href="'https://sourcing.gowawe.com/en/rfq/' + item.id"
              color="primary"
              class="mx-1"
              target="_blank"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-link
              </v-icon>
            </v-btn>
          </template>
          <span>See on Gowawe</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "Rfq",
  props: {
    member_id: {
      required: false,
    },
  },
  data() {
    return {
      selected: [],
      dialog: false,
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      tabIndis: [
        {
          index: 0,
          statu: true,
          val: "pending",
          loader: true,
          name: "Pending",
        },
        {
          index: 1,
          statu: false,
          val: "approved",
          loader: false,
          name: "Approved",
        },
        {
          index: 2,
          statu: false,
          val: "rejected",
          loader: false,
          name: "Rejected",
        },
      ],
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Title", value: "c2", sortable: false },
        { text: "Category", value: "c3", sortable: false },
        { text: "Quantity", value: "c4", sortable: false },
        { text: "Unit", value: "c5", sortable: false },
        { text: "Member", value: "c6", sortable: false },
        { text: "Offers", value: "c7", sortable: false },
        { text: "State", value: "c8", sortable: false },
        { text: "Moment", value: "c9", sortable: false },
        { text: "Date", value: "c10", sortable: false },
        { text: "type", value: "c11", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    process: function(item, type) {
      this.actionLock = true;
      const dataTo = item.member.user_id.toString();

      let notifyMessage;

      let url = "";
      if (type == "approved") {
        url =
          process.env.VUE_APP_API_URL +
          "quotation/" +
          item.id +
          "/approve?api_token=" +
          this.token;
        notifyMessage = {
          to: dataTo,
          type: "rfq",
          statu: true,
        };
      } else if (type == "rejected") {
        url =
          process.env.VUE_APP_API_URL +
          "quotation/" +
          item.id +
          "/reject?api_token=" +
          this.token;
        notifyMessage = {
          to: dataTo,
          type: "rfq",
          statu: false,
        };
      }

      this.$socket.emit("sendNotify", notifyMessage);

      axios.get(url).then((res) => {
        this.getDataFromApi(this.activeTab);
      });
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = process.env.VUE_APP_API_URL + "quotation/bulkdelete";
        } else if (type == "approve") {
          url = process.env.VUE_APP_API_URL + "quotation/approve";
        } else if (type == "reject") {
          url = process.env.VUE_APP_API_URL + "quotation/reject";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      if (this.dialogAction.type == "delete") {
        this.desserts = this.desserts.filter((item, index) => {
          return this.dialogAction.data.indexOf(item.id) == -1;
        });
      }

      axios
        .post(this.dialogAction.url, {
          id: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          if (
            this.dialogAction.type == "approve" ||
            this.dialogAction.type == "reject"
          ) {
            this.getDataFromApi(this.activeTab);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.tabIndis[a].loader = true;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.title,
            c3: item.category.title,
            c4: item.quantity,
            c5: item.unit ? item.unit.name : "",
            c6: item.member.name,
            c7: item.quotation_request_offers_count,
            c8: item.state,
            c9: this.parseDateTime(item.created_at),
            c10: item.created_at,
            c11: item.rfq_type,
            actions: null,
            member: item.member,
            category: item.category,
            item: item,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.tabIndis.find((x) => (x.loader = false));
        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    async getDesserts() {
      const fields = [
        {
          field: "state",
          op: "=",
          value: this.tabIndis[this.activeTab].val,
        },
        {
          field: "title",
          op: "like",
          value: this.search,
        },
      ];

      if (this.member_id) {
        fields.push({
          field: "member_id",
          op: "=",
          value: this.member_id,
        });
      }

      const sort = [
        {
          dir: "desc",
          field: "created_at",
        },
      ];

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            "quotation/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        );
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              User ID
            </th>
            <th class="text-left">
              User Name
            </th>
            <th class="text-left">
              Phone Number
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              URL
            </th>
            <th class="text-left">
              Registration Country
            </th>
            <th class="text-left">
              Statu
            </th>
            <th class="text-left">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in onlinemembers" :key="'on-' + index">
            <td>{{ item.data.id }}</td>
            <td>
              {{ item.data.name }}
            </td>
            <td>{{ item.data.phone }}</td>
            <td>{{ item.data.email }}</td>
            <td>{{ item.data.url }}</td>
            <td>
              <div v-html="matchCountry(item.data.country)"></div>
            </td>
            <td><span class="realtimeSignal"></span></td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    link
                    color="primary"
                    ><v-icon>
                      mdi-message-text
                    </v-icon></v-btn
                  >
                </template>
                <span>Chat Now</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "UserOnlineStatu",
  props: {
    onlinemembers: {
      required: true,
    },
    countries: {
      required: true,
    },
  },
  methods: {
    matchCountry(id) {
      if (this.countries.find((a) => a.id == id)) {
        let cu = this.countries.find((a) => a.id == id);
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
};
</script>

<style>
.realtimeSignal {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #2acf00;
  display: block;
}
</style>

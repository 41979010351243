<template>
  <div>
    <v-btn color="primary" small :loading="loading" v-on:click="getList()"
      >Refresh List</v-btn
    >
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              User ID
            </th>
            <th class="text-left">
              Store Name
            </th>
            <th class="text-left">
              Store URL
            </th>
            <th class="text-left">
              Phone Number
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Registration Country
            </th>
            <th class="text-left">
              Number of Visits
            </th>
            <th class="text-left">Last Visit Date</th>
            <th class="text-left">Detail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in historymembers" :key="'hs-' + index">
            <td>{{ item.store_member.user_id }}</td>
            <td>
              {{ item.store_name }}
            </td>
            <td>
              {{ item.store_url }}
            </td>
            <td>
              +{{ item.store_member.country_code }}
              {{ item.store_member.phone }}
            </td>
            <td>
              {{ item.store_email }}
            </td>
            <td>
              <div v-html="matchCountry(item.store_member.country_id)"></div>
            </td>
            <td>{{ item.number_of_visits }}</td>
            <td>{{ item.updated_at }}</td>
            <td>
              <v-btn
                elevation="0"
                link
                color="primary"
                :to="
                  '/members/edit/' +
                    item.interaction_member.id +
                    '/detail/visit/' +
                    item.id
                "
                ><v-icon>
                  mdi-history
                </v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserHistoryStatu",
  props: ["countries"],
  data: function() {
    return {
      data: null,
      historymembers: [],
      loading: true,
    };
  },
  methods: {
    getList() {
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "member-select-visits/" +
            this.$route.params.id
        )
        .then((res) => {
          this.historymembers = res.data.visits.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    matchCountry(id) {
      if (this.countries.find((a) => a.id == id)) {
        let cu = this.countries.find((a) => a.id == id);
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>

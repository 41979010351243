<template>
  <div>
    <v-card class="p-3">
      <v-row class="justify-start">
        <v-col xs="12" sm="12" md="12" lg="9" cols="9">
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Company Name"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.company_name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Contact Name"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.address.first_name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Contact Position"
                item-text="name"
                item-value="id"
                v-model="formdata.wizarddata.model.contact_position_id"
                :items="[
                  { name: 'General Manager', id: 1961 },
                  { name: 'Sales Manager', id: 454 },
                ]"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Phone"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.member.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Mobile"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.mobile"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Country"
                item-text="name"
                item-value="id"
                v-model="country_id"
                :items="countries"
                v-on:change="setValue()"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="City / Town"
                item-text="name"
                item-value="id"
                v-model="formdata.wizarddata.model.address.city_id"
                :items="citiesArray"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Zip / Postal Code"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.address.postcode"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Address Line 1"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.address.address1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Address Line 2"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.address.address2"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Description TR"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Description EN"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.description_en"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Description AR"
                :rules="rules"
                hide-details="auto"
                v-model="formdata.wizarddata.model.description_ar"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="12" sm="12" md="12" lg="3" cols="3" class="text-center">
          <div class="card_wrapper">
            <h5>Contact Photo</h5>
            <v-avatar size="200">
              <v-img
                lazy-src="https://via.placeholder.com/200"
                max-height="200"
                max-width="200"
                :src="formdata.wizarddata.model.contact_photo"
              ></v-img>
            </v-avatar>
            <v-btn block class="my-4" disabled color="primary"
              >Update Photo</v-btn
            >
          </div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Web Site"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-web"
                v-model="formdata.wizarddata.model.website"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-email"
                v-model="formdata.wizarddata.model.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Social Facebook"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-facebook"
                v-model="formdata.wizarddata.model.social_facebook"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Social Twitter"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-twitter"
                v-model="formdata.wizarddata.model.social_twitter"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Social Linkedin"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-linkedin"
                v-model="formdata.wizarddata.model.social_linkedin"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Social Youtube"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-youtube"
                v-model="formdata.wizarddata.model.social_youtube"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Social Instagram"
                :rules="rules"
                hide-details="auto"
                prepend-icon="mdi-instagram"
                v-model="formdata.wizarddata.model.social_instagram"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog.statu" persistent hide-overlay width="300">
      <v-card :color="dialog.color" dark>
        <v-card-text class="p-3 white--text text-center">
          <v-icon>
            mdi-alert-octagon
          </v-icon>
          {{ dialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="dialog.statu = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TabSubcontent0",
  props: ["formdata", "countries"],
  data: function() {
    return {
      dialog: {
        statu: false,
        color: "red",
        text: "Loading, Please wait",
      },
      data: null,
      token: localStorage.getItem("authToken"),
      citiesArray: [],
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
    };
  },
  methods: {
    setValue: function(val) {
      this.getCities();
      this.country_id = val;
    },
    getCities: function() {
      this.citiesArray = [];
      let url =
        "https://gowawe.com/api/admin/country/" +
        this.country_id +
        "/cities?api_token=" +
        this.token;
      axios.get(url).then((res) => {
        res.data.data.forEach((item) => {
          this.citiesArray.push({
            id: item.id,
            name: item.name,
          });
        });
      });
    },
  },
  computed: {
    country_id: {
      get: function() {
        return this.formdata.wizarddata.model.address
          ? this.formdata.wizarddata.model.address.country_id
          : (this.formdata.wizarddata.model.address = {});
      },
      set: function(val) {
        if (val) {
          this.formdata.wizarddata.model.address.country_id = val;
        }
      },
    },
  },
  created() {
    this.getCities();
  },
};
</script>

<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              User ID
            </th>
            <th class="text-left">
              Avatar
            </th>
            <th class="text-left">
              User Name
            </th>
            <th class="text-left">
              Phone Number
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              URL
            </th>
            <th class="text-left">
              Registration Country
            </th>
            <th class="text-left">
              Type
            </th>
            <th class="text-left">
              Time
            </th>
            <th class="text-left">
              Moment
            </th>
            <th class="text-left">
              Statu
            </th>
          </tr>
        </thead>
        <tbody v-if="dataList">
          <tr v-for="(item, index) in dataList" :key="'on-' + index">
            <td>{{ item.id }}</td>
            <td class="py-2">
              <v-avatar max-height="60" max-width="60">
                <v-img :src="item.image"></v-img>
              </v-avatar>
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.url }}</td>
            <td><div v-html="matchCountry(item.country)"></div></td>
            <td>
              <v-chip
                :color="
                  item.is_buyer == '' && item.is_seller == '1'
                    ? 'success'
                    : item.is_buyer == '1' && item.is_seller == '1'
                    ? 'secondary'
                    : 'error'
                "
              >
                {{
                  item.is_buyer == "" && item.is_seller == "1"
                    ? "Seller"
                    : item.is_buyer == "1" && item.is_seller == "1"
                    ? "Both"
                    : "Buyer"
                }}
              </v-chip>
            </td>
            <td>{{ moment(item.time).format("hh:mm:ss") }}</td>
            <td>{{ moment(item.time).fromNow() }}</td>
            <td><span class="realtimeSignal"></span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ReportsTabcontent2",
  props: {
    memberdomain: {
      required: true,
    },
    onlinemembersset: {
      required: true,
    },
    countries: {
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
      dataList: [],
    };
  },
  watch: {
    onlinemembersset: function(newVal, oldVal) {
      if (this.memberdomain) {
        try {
          const resultData = newVal.map((a) => {
            if (a.data && a.data.type !== null) {
              let xurl = new URL(a.data.url).host.split(".");
              if (xurl.length > 0) {
                let xdomain = xurl[0];
                if (
                  xdomain == this.memberdomain.toLowerCase() &&
                  a.data.id !== this.myid
                ) {
                  return a.data;
                }
              }
            }
          });

          this.dataList = resultData.filter((item) => item != undefined);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  methods: {
    matchCountry(id) {
      if (this.countries.find((a) => a.id == id)) {
        let cu = this.countries.find((a) => a.id == id);
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
};
</script>

<style>
.realtimeSignal {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #2acf00;
  display: block;
}
</style>

<template>
  <div>
    <h6>{{ item.label }}</h6>
    <v-layout wrap v-if="data">
      <v-checkbox
        class="mr-md-4"
        v-for="(check, index) in data"
        :key="index"
        :label="check.name"
        :value="check.id"
        v-model="selected"
      ></v-checkbox>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "LookupCheckbox",
  props: {
    item: {
      required: true,
    },
  },
  created() {
    this.getLookup(this.item)
      .then((res) => {
        this.data = res.reverse();
        this.loading = false;
      })
      .catch((error) => console.log(error));
  },
  computed: {
    selected: {
      get: function() {
        if (this.item.value.value != null) {
          if (typeof this.item.value.value == "string") {
            return JSON.parse(this.item.value.value).map((a) => {
              return typeof a == "string" ? Number(a) : a;
            });
          } else {
            return this.item.value.value;
          }
        }
        console.log(this.item.value.value);
      },
      set: function(val) {
        this.item.value.value = val;
      },
    },
  },

  data() {
    return {
      data: null,
      token: localStorage.getItem("authToken"),
      loading: true,
      mselected: [],
    };
  },
  methods: {
    async getLookup(item) {
      let val = item.lookup_type_id;
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "lookup/filter?page=1&per_page=100&api_token=" +
            this.token,
          {
            fields: [
              {
                field: "lookup_type_id",
                op: "=",
                value: val,
              },
            ],
          }
        )
        .then(function(response) {
          return response.data.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

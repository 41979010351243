<template>
  <v-card class="p-3">
    <v-card-title>
      Member Search History
      <v-btn
        class="ml-3"
        color="primary"
        small
        :loading="loading"
        v-on:click="getList"
        >Refresh List</v-btn
      >
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" v-if="!member_id">
              Member ID
            </th>
            <th class="text-left" v-if="!member_id">
              Member Name
            </th>
            <th class="text-left">
              Date & Time
            </th>
            <th class="text-left">
              Search Type
            </th>
            <th class="text-left">
              Keyword
            </th>
            <th class="text-left">
              Device Type
            </th>
            <th class="text-left">
              Total results
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="'hs-' + index">
            <td v-if="!member_id">
              <a
                :href="'/members/edit/' + (item.member ? item.member.id : '')"
                >{{ item.member ? item.member.id : "" }}</a
              >
            </td>
            <td v-if="!member_id">
              <a
                :href="'/members/edit/' + (item.member ? item.member.id : '')"
                >{{ item.member ? item.member.name : "" }}</a
              >{{ item.member ? "" : "Anonymous" }}
            </td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.search_type }}</td>
            <td>{{ item.keyword }}</td>
            <td>{{ item.device_type }}</td>
            <td>{{ item.total_result }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      :total-pages="totalPages"
      :current-page="currentPage"
      :per_page="per_page"
      :max-visible-pages="5"
      @page-changed="getList"
    ></pagination>
  </v-card>
</template>

<script>
import axios from "axios";
import Pagination from "../../Components/Pagination.vue";
export default {
  name: "SearchHistory",
  // props: ["member_id"],
  components: {
    Pagination,
  },
  props: {
    member_id: {
      type: String,
      required: false,
    },
  },
  data: function() {
    return {
      data: [],
      loading: true,
      token: localStorage.getItem("authToken"),
      currentPage: 1,
      totalPages: 1,
      per_page: 15,
    };
  },
  methods: {
    getList(page = 1) {
      console.log(process.env.VUE_APP_API_UR);
      this.loading = true;
      let url =
        process.env.VUE_APP_API_URL +
        "search-log" +
        // (this.member_id ? "/" + this.member_id : "") +
        "?api_token=" +
        this.token +
        "&page=" +
        page +
        "&per_page=" +
        this.per_page +
        (this.member_id ? "&member_id=" + this.member_id : "");
      axios
        .get(url)
        .then((response) => {
          this.data = response.data.data.data;
          this.currentPage = response.data.data.current_page;
          this.per_page = response.data.data.per_page;
          this.totalPages = response.data.data.last_page;
          this.totalRecords = response.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
  },
  created() {
    this.getList();
  },
};
</script>

<template>
  <div>
    <h6>{{ item.label }}</h6>
    <v-layout wrap>
      <v-text-field
        class="mr-md-4"
        type="number"
        v-for="(textfield, index) in data"
        :key="index"
        :label="textfield.name"
        v-model="selected[textfield.id]"
        v-on:input="setValue()"
      ></v-text-field>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "LookupNumerica",
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      data: [],
      token: localStorage.getItem("authToken"),
      loading: true,
      selected: {},
    };
  },
  created() {
    this.getLookup(this.item)
      .then((res) => {
        this.data = res.reverse();
        this.loading = false;
        this.setItems();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    setItems: function() {
      if (this.item.value.value != null) {
        if (typeof this.item.value.value == "string") {
          this.selected = JSON.parse(this.item.value.value);
        }
      }

      if (!this.item.value.value || typeof this.item.value.value != "string") {
        this.item.value.value = {};
      }
    },
    setValue: function() {
      if (
        typeof this.selected == "object" &&
        this.selected !== undefined &&
        this.selected
      ) {
        this.item.value.value = JSON.stringify(this.selected);
      }
    },
    async getLookup(item) {
      let val = item.lookup_type_id;
      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "lookup/filter?page=1&per_page=100&api_token=" +
            this.token,
          {
            fields: [
              {
                field: "lookup_type_id",
                op: "=",
                value: val,
              },
            ],
          }
        )
        .then(function(response) {
          return response.data.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <div class="table_wrapper">
    <div class="tab_wrapper d-flex flex-wrap w-100 align-items-center mb-2">
      <v-btn
        v-on:click="getDataFromApi(0)"
        color="primary"
        :loading="tabIndis[0].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[0].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(1)"
        color="success"
        :loading="tabIndis[1].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[1].name }}</span>
      </v-btn>
      <v-btn
        v-on:click="getDataFromApi(2)"
        color="error"
        :loading="tabIndis[2].loader"
        class="mx-1"
      >
        <span>{{ tabIndis[2].name }}</span>
      </v-btn>
    </div>

    <div class="filter_wrapper mb-2" v-if="mfieldsFilter">
      <v-btn icon v-on:click="resetFilter">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-chip v-for="(a, i) in mfields" :key="i" v-show="a.field == 'id'">{{
        a.value
      }}</v-chip>
    </div>

    <v-data-table
      :headers="headers"
      :items="desserts"
      v-model="selected"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      show-select
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Products</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title>{{ tabIndis[activeTab].name }}</v-toolbar-title>
          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="success"
            class="ml-2"
            v-on:click="selectedAction('approve')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Approve Selected ({{ selected.length }})
          </v-btn>
          <v-btn
            small
            color="error"
            class="ml-2"
            v-on:click="selectedAction('reject')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Reject Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            v-on:keyup="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cdomain`]="{ item }">
        <v-chip
          :color="
            item.cdomain == 'No Domain' ? 'deep-orange lighten-2' : 'blue'
          "
          dark
          v-on:click="openModal(item)"
        >
          {{ item.cdomain }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="activeTab == 0 || activeTab == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'approved')"
              color="success"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>Approve</span>
        </v-tooltip>

        <v-tooltip top v-if="activeTab == 0 || activeTab == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'/products/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip right v-if="activeTab == 0 || activeTab == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="process(item, 'rejected')"
              color="error"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="190">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Please Select
        </v-card-title>
        <v-card-text>What action do you want to do?</v-card-text>
        <v-card-subtitle
          >Open a store or filter only these store products.</v-card-subtitle
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="openUrl">
            Open Store
          </v-btn>
          <v-btn color="yellow darken-1" text @click="openViewAccountUrl">
            View Account
          </v-btn>
          <v-btn color="green" text @click="filterStore">
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "Products",
  data() {
    return {
      mfieldsFilter: false,
      dialog: false,
      dialog2: false,
      moment: moment,
      storeItem: null,
      selected: [],
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      tabIndis: [
        {
          index: 0,
          statu: true,
          val: "null",
          loader: true,
          name: "Pending",
        },
        {
          index: 1,
          statu: false,
          val: "1",
          loader: false,
          name: "Approved",
        },
        {
          index: 2,
          statu: false,
          val: "0",
          loader: false,
          name: "Rejected",
        },
      ],
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Category", value: "c2", sortable: false },
        { text: "Title", value: "c3", sortable: false },
        { text: "Store", value: "c4", sortable: false },
        { text: "Domain", value: "cdomain", sortable: false },
        { text: "Is Approved", value: "c5", sortable: false },
        { text: "Moment", value: "c6", sortable: false },
        { text: "Created At", value: "c7", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(0);
      },
      deep: true,
    },
    $route(to, from) {
      if (to.params.memberid == undefined) {
        const Inn = this.mfields.findIndex((a) => a.field == "member_id");
        this.mfields.splice(Inn, 1);
        this.getDataFromApi(0);
      }
    },
  },
  methods: {
    resetFilter: function() {
      let index1 = this.mfields.findIndex((a) => a.field == "id");
      this.mfields.splice(index1, 1);
      this.mfieldsFilter = false;
      this.getDataFromApi(this.activeTab);
    },
    openViewAccountUrl: function() {
      let accountUrl;
      if (this.storeItem.item.member.id) {
        accountUrl = "/members/edit/" + this.storeItem.item.member.id;
        window.open(accountUrl, "_blank");
      } else {
        alert("there is no member ");
      }
      this.dialog2 = false;
    },
    openUrl: function() {
      let storeUrl;

      if (this.storeItem.item.store.domain) {
        storeUrl =
          "https://" + this.storeItem.item.store.domain + ".gowawe.com";
      } else {
        storeUrl =
          "https://gowawe.com/en/store/" + this.storeItem.item.store.member_id;
      }
      window.open(storeUrl, "_blank");
      this.dialog2 = false;
    },
    filterStore: function() {
      if (this.mfields.filter((a) => a.field == "id").length == 0) {
        this.mfields.push({
          field: "id",
          op: "=",
          value: this.storeItem.item.member.id,
        });
      }
      this.dialog2 = false;
      this.mfieldsFilter = true;
      this.getDataFromApi(this.activeTab);
    },
    openModal: function(item) {
      console.log({ item });
      this.storeItem = item;
      this.dialog2 = true;
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = "https://gowawe.com/api/admin/product/bulkdelete";
        } else if (type == "approve") {
          url = "https://gowawe.com/api/admin/product/approve";
        } else if (type == "reject") {
          url = "https://gowawe.com/api/admin/product/reject";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      if (this.dialogAction.type == "delete") {
        this.desserts = this.desserts.filter((item, index) => {
          return this.dialogAction.data.indexOf(item.id) == -1;
        });
      }

      axios
        .post(this.dialogAction.url, {
          id: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          if (
            this.dialogAction.type == "approve" ||
            this.dialogAction.type == "reject"
          ) {
            this.getDataFromApi(this.activeTab);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    process: function(item, type) {
      item = item.item;
      const dataTo = item.member?.user_id.toString();

      let notifyMessage;

      let url = "";
      if (type == "approved") {
        url =
          process.env.VUE_APP_API_URL +
          "product/" +
          item.id +
          "/approve?api_token=" +
          this.token;

        notifyMessage = {
          to: dataTo,
          type: "product",
          statu: true,
        };
      } else if (type == "rejected") {
        url =
          process.env.VUE_APP_API_URL +
          "product/" +
          item.id +
          "/reject?api_token=" +
          this.token;
        notifyMessage = {
          to: dataTo,
          type: "product",
          statu: false,
        };
      }

      this.$socket.emit("sendNotify", notifyMessage);

      axios.get(url).then((res) => {
        this.getDataFromApi(this.activeTab);
      });
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    getDataFromApi(a = null) {
      if (a) {
        this.activeTab = a;
        this.tabIndis[a].loader = true;
      }
      this.loading = true;

      this.mfields.find((a) => {
        switch (a.field) {
          case "is_approved":
            a.value = this.tabIndis[this.activeTab].val;
            break;
          case "term":
            a.value = this.search;
            break;
          case "status":
            a.value = "all";
            break;
        }
      });

      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item) => {
          const key = {
            c1: item.id,
            c2: item.category.title,
            c3: item.title,
            c4: item.store.company_name,
            cdomain: item.store.domain ? item.store.domain : "No Domain",
            c5: item.is_approved
              ? "Yes"
              : item.is_approved === null
              ? "Pending"
              : "No",
            c6: this.parseDateTime(item.created_at),
            c7: item.created_at,
            actions: null,
            item: item,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.tabIndis.find((x) => (x.loader = false));
        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      this.selected = [];

      let fields = this.mfields;
      const sort = [
        {
          dir: "desc",
          field: "created_at",
        },
      ];

      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "product/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        )
        .then(function(response) {
          return response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  created() {
    this.mfields = [
      {
        field: "is_approved",
        op: "=",
        value: this.tabIndis[this.activeTab].val,
      },
      {
        field: "term",
        op: "LIKE",
        value: this.search,
      },
      {
        field: "status",
        op: "=",
        value: "all",
      },
    ];
    if (this.$route.params.memberid || this.$route.params.id) {
      const mID = this.$route.params.id
        ? this.$route.params.id
        : this.$route.params.memberid;
      this.mfields.push({
        field: "member_id",
        op: "=",
        value: mID,
      });
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

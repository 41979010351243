<template>
  <div>
    <p>You can edit: {{ name }}</p>
    <v-container fluid>
      <v-autocomplete
        v-model="formdata.wizarddata.model.business_categories"
        :items="businesstypes"
        label="Select Item"
        item-text="name"
        deletable-chips
        item-value="id"
        chips
        multiple
      ></v-autocomplete>
    </v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-autocomplete
          v-model="formdata.wizarddata.model.selected_categories_ids"
          :items="formdata.wizarddata.categories"
          label="Select Item"
          deletable-chips
          item-text="title"
          item-value="id"
          chips
          multiple
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Tabcontent1",
  props: ["name", "formdata", "businesstypes"],
  mounted() {
    this.formdata.wizarddata.model.selected_categories_ids = this.formdata.wizarddata.model.selected_categories_ids?.map(
      (b) => {
        return Number(b);
      }
    );
    this.formdata.wizarddata.model.business_categories = this.formdata.wizarddata.model.business_categories?.map(
      (a) => {
        return a.id;
      }
    );
  },
};
</script>
